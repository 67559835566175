import React from "react";

const FeaturedTools = ({ tools }) => {
  const featuredItems = tools?.slice(0, 5) ?? [];

  return (
    <div className="w-full sticky top-5">
      <h1 className="text-3xl font-bold mb-5">Featured</h1>

      <div className="flex flex-col gap-5">
        {featuredItems.map((category, key) => (
          <div className="w-full flex flex-col gap-4" key={key}>
            <div
              className="w-full bg-gray-400/20 hover:bg-gray-400/40 cursor-pointer backdrop-blur rounded-lg p-4 flex flex-col gap-3 shadow transition-all"
              key={key}
            >
              <div className="flex flex-col">
                <div className="flex items-center justify-between gap-2">
                  <h1 className="text-lg whitespace-nowrap text-ellipsis">
                    {category.name}
                  </h1>
                  <h5 className="text-xs/6 opacity-70 whitespace-nowrap">
                    {new Date(category.last_update_date).toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      }
                    )}
                  </h5>
                </div>
                <h1 className="text-base text-[var(--primary-color)]">
                  Rank: {category.rank}
                </h1>
                <p className="text-xs opacity-70">{category.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturedTools;
