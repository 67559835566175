import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect } from "react";
import { Button, Dialog, DialogPanel } from "@headlessui/react";
import { formatSentence } from "../../utils";
import {
  Close,
  FacebookRounded,
  Favorite,
  Instagram,
  Public,
  Twitter,
} from "@mui/icons-material";
import { addToFavourite } from "../../utils/api";
import {
  FacebookShareButton,
  InstapaperShareButton,
  TwitterShareButton,
} from "react-share";
import clsx from "clsx";
import SocialShare from "../snippets/social-share";

const ToolDetailsModal = ({ singleTool, toolModalOpen, setToolModalOpen }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { currentTheme } = useSelector((state) => state.theme);

  const handelAddToFavourite = async (tool) => {
    await addToFavourite(user, tool._id, dispatch, setToolModalOpen);
  };

  useEffect(() => {
    if (toolModalOpen) {
      setTimeout(() => {
        document.documentElement.style.overflow = "auto";
      }, 500);
    } else {
      document.documentElement.style.removeProperty("overflow");
    }
  }, [toolModalOpen]);

  const shareUrl = `${process.env.REACT_APP_BASE_URL}/${formatSentence(
    singleTool?.name
  )}/details?hash=${singleTool?.hash}`;

  return (
    <Fragment>
      <Dialog
        onClose={() => setToolModalOpen(false)}
        open={toolModalOpen}
        as="div"
        className={`${currentTheme} relative z-[1001] focus:outline-none`}
      >
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <DialogPanel
              transition
              className="relative w-full max-w-2xl rounded-2xl p-10 backdrop-blur-xl shadow-filter transition duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
            >
              <Button
                onClick={() => setToolModalOpen(false)}
                className="absolute right-4 top-4 text-[var(--text-color-base)] hover:scale-110 transition-all"
              >
                <Close />
              </Button>

              <div className="w-full flex flex-col items-center gap-3">
                <div
                  style={{ backgroundImage: `url(/assets/bubble_dark.svg)` }}
                  className="w-40 h-40 rounded-full backdrop-blur-xl shadow-filter bg-center bg-no-repeat bg-cover text-[var(--text-color-base)] flex flex-col items-center justify-center gap-2"
                >
                  <h4 className="text-sm font-normal text-center">
                    Total Global Visit
                  </h4>
                  <h1 className="text-2xl/5 font-bold text-center">
                    {singleTool?.stats?.["total-visits"]?.toLocaleString() || 0}
                  </h1>
                </div>

                {singleTool ? (
                  <table className="w-full table-auto text-lg font-medium text-[var(--text-color-base)] border-spacing-y-3 border-separate">
                    <tbody>
                      <tr>
                        <td className="w-[120px] opacity-70">Name:</td>
                        <td>{singleTool.name}</td>
                      </tr>
                      <tr>
                        <td className="w-[120px] opacity-70">Rank:</td>
                        <td>
                          <div className="w-max px-2 py-1 bg-[var(--bg-color)] rounded">
                            {singleTool.rank}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="w-[120px] opacity-70">Use case:</td>
                        <td>{singleTool.use_case}</td>
                      </tr>
                      <tr>
                        <td className="w-[120px] opacity-70 align-top">
                          Description:
                        </td>
                        <td className="break-all">
                          {singleTool.paraphrased_description}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-[120px] opacity-70 align-top">
                          Link:
                        </td>
                        <td className="break-all">
                          <a
                            className="underline"
                            href={singleTool.url}
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => setToolModalOpen(false)}
                          >
                            {singleTool.url}
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <p>Loading...</p>
                )}

                <div className="w-full grid grid-flow-col gap-5">
                  <Button
                    onClick={() => handelAddToFavourite(singleTool)}
                    className="flex items-center justify-center gap-2 py-[10px] px-3 rounded-full border-2 border-[var(--text-color-base)] text-[var(--text-color-base)] transition-all hover:bg-[var(--primary-color)] hover:border-[var(--primary-color)] hover:text-white shadow-2xl hover:shadow-[#9C3CD6]/40"
                  >
                    <Favorite />
                    Add To Favorite
                  </Button>
                  <Link
                    state={{ data: singleTool }}
                    to={`/${formatSentence(singleTool?.name)}/details?hash=${
                      singleTool?.hash
                    }`}
                    className="flex items-center justify-center gap-2 py-[10px] px-3 rounded-full border-2 border-[var(--primary-color)] bg-[var(--primary-color)] text-white shadow-2xl hover:shadow-[#9C3CD6]/40 transition-all"
                  >
                    <Public />
                    View Details
                  </Link>
                </div>

                <div className="w-full my-2 h-px bg-[var(--border-color)]" />

                <div className="mt-5 w-max mx-auto">
                  <SocialShare shareUrl={shareUrl} />
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default ToolDetailsModal;
