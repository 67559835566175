import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    collection: 'FutureTools',
};

const categorySlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        setCollection: (state, action) => {
            state.collection = action.payload;
        },
    },
});

export const {
    setCollection,
} = categorySlice.actions;

export default categorySlice.reducer;
