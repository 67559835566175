import { Avatar } from "@mui/material";
import { useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button } from "@headlessui/react";
import React from "react";

function ProfileLayout({ children }) {
  const { currentTheme } = useSelector((state) => state.theme);
  const user = useSelector((state) => state.auth.user);
  let { userId } = useParams();
  const navigate = useNavigate();

  if (!user || user._id !== userId) {
    return;
  }

  const handelBack = () => {
    navigate("/");
  };

  return (
    <div
      className={`w-full h-full bg-repeat relative ${
        currentTheme === "light-theme" ? "bg-cover bg-no-repeat bg-center" : ""
      }`}
      style={{
        backgroundImage: `${
          currentTheme === "light-theme"
            ? "url('/assets/banner-light-bg.png')"
            : "url('/assets/banner-dark-bg.png'), linear-gradient(90deg, rgba(2, 93, 44, 0.08) 0%, rgba(238, 189, 62, 0.08) 25%, rgba(79, 194, 187, 0.08) 50%, rgba(135, 21, 103, 0.08) 75%, rgba(90, 0, 161, 0.08) 100%)"
        }`,
        width: "100%",
      }}
    >
      <div className="w-full h-full backdrop-blur-2xl">
        <Button
          title="Back"
          className="absolute left-8 top-2 flex items-center justify-center rounded-full bg-[var(--primary-color)] w-10 aspect-square text-sm text-[var(--text-color-dark)] data-[hover]:bg-[var(--primary-color)] data-[hover]:opacity-80 transition-all data-[active]:bg-[var(--primary-color)]"
          onClick={handelBack}
        >
          <ArrowBackIosIcon fontSize="small" className="ml-1.5" />
        </Button>
        <div className="container max-w-6xl p-14 mx-auto h-[calc(100vh-77px)] flex gap-1">
          <aside className="w-full p-6 sm:w-60 bg-white/30 dark:bg-gray-50/5 rounded-l-lg">
            <div className="w-full">
              <div className="flex flex-col items-center pb-10">
                <Avatar className="!h-[100px] !w-[100px]" />
              </div>
            </div>

            <nav className="space-y-8 text-sm">
              <div className="space-y-2">
                <h2 className="text-sm font-semibold tracking-widest uppercase text-[var(--text-color-base)] opacity-70">
                  Profile
                </h2>
                <div className="flex flex-col space-y-1">
                  <NavLink
                    className="text-[var(--text-color-base)] hover:text-[var(--primary-color)] aria-[current=page]:text-[var(--primary-color)] transition-all text-lg"
                    to={`/${user._id}/settings`}
                  >
                    Settings
                  </NavLink>
                  <NavLink
                    className="text-[var(--text-color-base)] hover:text-[var(--primary-color)] aria-[current=page]:text-[var(--primary-color)] transition-all text-lg"
                    to={`/${user._id}/preferences`}
                  >
                    Preferences
                    {/*<East fontSize="14px" className="ml-2"/>*/}
                  </NavLink>
                </div>
              </div>
            </nav>
          </aside>
          <div className="w-full bg-white/30 dark:bg-gray-50/5 rounded-r-lg p-6">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileLayout;
