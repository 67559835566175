import React, { useRef } from "react";
import { Circle } from "@mui/icons-material";

const ToolCard = ({ tools, loading, blueprints, setParams }) => {
  const observer = useRef();

  const lastItemRef = (node) => {
    if (loading || blueprints.totalTools <= tools.length) return;

    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setParams();
      }
    });
    if (node) observer.current.observe(node);
  };

  return (
    <div className="w-full flex flex-col gap-10">
      {tools.map((category, key) => (
        <div
          ref={key === tools.length - 1 ? lastItemRef : null}
          className="w-full flex flex-col gap-4"
          key={key}
        >
          <div className="flex items-center gap-2">
            <Circle fontSize="small" className="text-[#9C3CD6]" />
            <h3 className="text-lg">
              {category.category}:
              <span className="px-3 py-1 bg-[#9C3CD6]/10 rounded-lg ml-2">
                {category.rank}
              </span>
            </h3>
          </div>
          <div
            className="w-full bg-gray-400/20 backdrop-blur rounded-lg p-5 flex flex-col gap-4 shadow"
            key={key}
          >
            <div className="flex flex-col">
              <div className="flex items-center justify-between gap-2">
                <h1 className="text-2xl">{category.name}</h1>
                <h5 className="text-sm/6 opacity-70">
                  {new Date(category.last_update_date).toLocaleDateString(
                    "en-US",
                    {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    }
                  )}
                </h5>
              </div>
              <p className="text-base opacity-70">{category.description}</p>
            </div>

            <img
              src={category.image}
              alt={category.name}
              className="w-full aspect-[16/6] object-cover rounded-lg"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ToolCard;
