import React, {useEffect, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const Pagination = ({currentPage, onClickLeftPage, onClickRightPage, itemsPerPage, totalValue, totalPages}) => {
    const [startValue, setStartValue] = useState(0);
    const [endValue, setEndValue] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setStartValue((currentPage - 1) * itemsPerPage + 1)
            setEndValue(currentPage * itemsPerPage)
            setLoading(false)
        }, 500)
    }, [currentPage])

    const isDisabled = totalPages ? (totalPages <= currentPage) : (totalValue < endValue);

    return (
        <>
            <button
                disabled={currentPage < 2 || loading}
                className={twMerge(currentPage < 2 ? 'bg-gray-300 text-[#201F28]' : 'bg-[#1B1A23]', 'h-10 w-10 rounded-full text-white')}
                onClick={onClickLeftPage}
            >
                <ChevronLeftIcon/>
            </button>
            <span
                className='inline-block bg-[#1B1A23] mx-2 text-white rounded-full px-5 py-2 transition ease-in-out delay-150'>
                {startValue}-{endValue}
            </span>
            <button
                disabled={isDisabled || loading}
                className={twMerge(isDisabled ? 'bg-gray-300 text-[#201F28]' : 'bg-[#1B1A23]', 'h-10 w-10 rounded-full text-white')}
                onClick={onClickRightPage}
            >
                <ChevronRightIcon/>
            </button>
        </>
    );
};

export default Pagination;
