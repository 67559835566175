import React, {Fragment} from 'react';
import useCanvas from '../../hooks/useCanvasBubbles';
import Loader from '../snippets/loader';
import {useSelector} from 'react-redux';

const CategoryBubble = ({categories, setCurrentCategory, loading}) => {
    const canvasRef = useCanvas('CATEGORY', categories, setCurrentCategory, null, null, null);
    const {currentTheme} = useSelector((state) => state.theme);

    return (
        <Fragment>
            {loading ? (
                <Loader/>
            ) : (
                <canvas
                    className={`${currentTheme === 'light-theme' ? 'bg-[var(--bg-color)] bg-cover bg-no-repeat' : 'bg-black'} bg-contain h-full object-contain`}
                    ref={canvasRef}
                    style={{
                        backgroundImage: `${currentTheme === 'light-theme' ? "url('/assets/banner-light-bg.png')" : ""}`,
                        width: '100%',
                    }}
                />
            )}
        </Fragment>
    );
};

export default CategoryBubble;
