// src/features/auth/authSlice.js
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    isAuthenticated: false,
    user: null,
    error: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess: (state, action) => {
            state.isAuthenticated = true;
            state.user = action.payload;
            state.error = null;
        },
        updateUser: (state, action) => {
            state.user = action.payload;
        },
        loginFailure: (state, action) => {
            state.isAuthenticated = false;
            state.error = action.payload;
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.user = null;
        },
    },
});

export const {loginSuccess, loginFailure, logout, updateUser} = authSlice.actions;

export default authSlice.reducer;
