import { useState, useEffect, useMemo, Fragment } from "react";
import ToolBubble from "../../components/bubbles/tool-bubble";
import AITable from "../../components/snippets/table";
import Pagination from "../../components/snippets/pagination";
import { useDispatch, useSelector } from "react-redux";
import { setShowOnlyTable } from "../../reducers/globalReducer";
import { getTool } from "../../utils/api/tool";
import usePagination from "../../hooks/usePagination";

function Tools() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const { selectedFilter } = useSelector((state) => state.tools);
  const { currentCategory } = useSelector((state) => state.global);
  const { showOnlyTable } = useSelector((state) => state.global);
  const BUBBLES_PER_PAGE = user?.bubblesPerPage || 50;
  const [blueprints, setBlueprints] = useState({
    page: 0,
    perPage: 0,
    totalTools: 0,
    totalPages: 0,
  });

  const [category, setCategory] = useState(currentCategory);
  const [dataSets, setDataSets] = useState({});
  const [loading, setLoading] = useState(false);
  const [tools, setTools] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterParams, setFilterParams] = useState({
    page: currentPage,
    bubbleSize: "",
    bubbleContent: "",
    period: "",
    bubbleColor: "green",
    perPage: BUBBLES_PER_PAGE,
  });

  const { onClickLeftPage, onClickRightPage } = usePagination(
    currentPage,
    setCurrentPage
  );

  const fetchTools = async (params) => {
    setLoading(true);
    await getTool(category, setTools, setBlueprints, params)
      .then((res) => {
        setDataSets({ ...dataSets, [params.page]: res });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selectedFilter && selectedFilter.filterData) {
      setFilterParams({ ...filterParams, ...selectedFilter.filterData });
      setDataSets({});
      setCurrentPage(1);
    }
  }, [selectedFilter]);

  useEffect(() => {
    dispatch(setShowOnlyTable(false));
  }, [dispatch]);

  useEffect(() => {
    setFilterParams({ ...filterParams, page: currentPage });
  }, [currentPage]);

  useEffect(() => {
    setDataSets({});
    setCurrentPage(1);
    setCategory(currentCategory);
  }, [currentCategory]);

  const fetchToolsDeps = useMemo(
    () => ({
      currentPage,
      filterParams,
      category,
      setTools,
    }),
    [currentPage, filterParams, category, setTools]
  );

  useEffect(() => {
    if (dataSets?.hasOwnProperty(filterParams.page)) {
      setBlueprints(dataSets[filterParams.page].blueprints);
      return setTools(dataSets[filterParams.page].tools);
    }

    fetchTools(filterParams);
  }, [fetchToolsDeps]);

  return (
    <Fragment>
      <div className={`relative w-full`}>
        {loading ? (
          <div className="w-full h-full flex items-center justify-center">
            <h4 className="text-base text-[var(--text-color-base)]">
              Loading...
            </h4>
          </div>
        ) : (
          <Fragment>
            {!showOnlyTable && (
              <Fragment>
                {tools.length ? (
                  <ToolBubble tools={tools} loading={loading} />
                ) : (
                  <div className="w-full h-full flex items-center justify-center">
                    <h4 className="text-base text-[var(--text-color-base)]">
                      No tools found!
                    </h4>
                  </div>
                )}
              </Fragment>
            )}

            <AITable
              showOnlyTable={showOnlyTable}
              loading={loading}
              tools={tools}
            />

            {blueprints.totalTools > BUBBLES_PER_PAGE && (
              <div className="fixed right-10 bottom-10">
                <Pagination
                  currentPage={currentPage}
                  onClickLeftPage={onClickLeftPage}
                  onClickRightPage={onClickRightPage}
                  itemsPerPage={BUBBLES_PER_PAGE}
                  totalValue={blueprints.totalTools}
                />
              </div>
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}

export default Tools;
