import { useDispatch, useSelector } from "react-redux";
import { Button, Transition } from "@headlessui/react";
import { Close } from "@mui/icons-material";
import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSearchResults } from "../../utils/api";
import { setCurrentCategory } from "../../reducers/globalReducer";

const GlobalSearch = () => {
  const { currentCategory } = useSelector((state) => state.global);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchItems, setSearchItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const searchRef = useRef(null);

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setSearchQuery("");
    }
  };

  const handelClick = (item) => {
    if (item.type === "category") {
      if (currentCategory && currentCategory === item.name)
        return setSearchQuery("");
      navigate("/");
      dispatch(setCurrentCategory(item.name));
    } else {
      const newName = item.name.toLowerCase().replace(" ", "-");
      navigate(`/${newName}/details?hash=${item.hash}`);
    }
    setSearchQuery("");
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setIsOpen(!!searchQuery);
    if (!searchQuery || searchQuery.length < 3) {
      return setSearchItems([]);
    }
    setLoading(true);
    const getData = setTimeout(() => {
      getSearchResults(searchQuery, setSearchItems).finally(() =>
        setLoading(false)
      );
    }, 500);
    return () => clearTimeout(getData);
  }, [searchQuery]);

  return (
    <div className="relative" ref={searchRef}>
      <div className="flex items-center bg-gray-200/30 rounded-full shadow-sm transition duration-500">
        <div className="flex items-center px-4 py-2 w-full max-w-72 min-w-[210px] space-x-4 rounded-lg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 opacity-30 text-[var(--text-color-base)]"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
          <input
            className="w-full pr-6 bg-transparent outline-none text-[var(--text-color-base)]"
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search keyword..."
          />

          {searchQuery && (
            <Close
              onClick={() => setSearchQuery("")}
              fontSize="small"
              className="absolute right-4 cursor-pointer text-[var(--text-color-base)] opacity-70 hover:opacity-100 hover:text-red-500 transition-all"
            />
          )}
        </div>
      </div>

      <Transition show={isOpen}>
        <div className="w-full absolute left-0 top-14 backdrop-blur bg-gray-200/30 z-[10001] divide-y divide-gray-300/50 rounded-xl text-sm/6 text-[var(--text-color-base)] transition duration-200 ease-in-out shadow-filter">
          <div className="p-3 h-full max-h-[500px] overflow-y-auto">
            {loading ? (
              <p className="text-center">Loading...</p>
            ) : searchQuery.length < 3 ? (
              <p className="text-center">Minimum: 3 characters required</p>
            ) : searchItems.length ? (
              <Fragment>
                {searchItems.map((item, index) => (
                  <Button
                    type="button"
                    onClick={() => handelClick(item)}
                    className="w-full text-left rounded-lg py-2 px-3 transition hover:bg-gray-300/20"
                    key={index}
                  >
                    <p className="font-semibold">{item.name}</p>
                    <p className="opacity-70">Type: {item.type}</p>
                  </Button>
                ))}
              </Fragment>
            ) : (
              <p className="text-center">No search results found!</p>
            )}
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default GlobalSearch;
