
import { Box, Dialog, DialogContent, DialogTitle, Fab, IconButton, Tab, Tabs, Typography } from '@mui/material';
import React, { Fragment, useState } from 'react'
import NavigationIcon from '@mui/icons-material/Navigation';
import CloseIcon from '@mui/icons-material/Close';
import { ArrowBack } from '@mui/icons-material';
import ToolSubmitForm from '../forms/tool-submit-form';


const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CustomTabPanel = ({ children, value, index, ...other }) => {

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const SubmitToolModal = () => {
    const [showForm, setShowForm] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [paymentData, setPaymentData] = useState({
        buttonId: '',
        name: '',
        price: 0,
    });

    const handleOpenTabModal = () => {
        setPaymentData({
            buttonId: '',
            name: '',
            price: 0,
        })
        setShowForm(false)
        setOpenModal(true);
        setTabValue(0)
    };

    const handleCloseTabModal = () => {
        setPaymentData({
            buttonId: '',
            name: '',
            price: 0,
        })
        setShowForm(false)
        setOpenModal(false);
    };

    const handleChangeTabs = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Fragment>


            {/* TODO: Add submit tool */}
            {/* <Fab variant="extended" size="small" color="primary" sx={{
                position: 'fixed', // Fixed position
                bottom: 30, // 16px from the bottom
                left: 16, // 16px from the right
                px: 3, // Padding left and right
            }} onClick={handleOpenTabModal}>
                <NavigationIcon sx={{ mr: 1 }} fontSize="18px" />
                Submit tool
            </Fab>
            <Dialog
                onClose={handleCloseTabModal}
                aria-labelledby="customized-dialog-title"
                open={openModal}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Pricing Plan
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseTabModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tabValue} onChange={handleChangeTabs} aria-label="basic tabs example">
                                <Tab label="Submit" {...a11yProps(0)} />
                                <Tab label="Become a member" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={tabValue} index={0}>
                            {
                                showForm ?
                                    <div>
                                        <button onClick={() => setShowForm(false)} className="mb-5">
                                            <ArrowBack fontSize="16px" />
                                            <span className="ml-1">Back</span>
                                        </button>
                                        <ToolSubmitForm paymentData={paymentData} />
                                    </div> :
                                    <div className="grid grid-cols-2 gap-5">

                                        <div>
                                            <h3 className="text-[32px] font-[400] mb-2">Submit a Tool</h3>
                                            <div className="bg-gray-500 rounded-xl border-2 border-white max-w-[570px] mx-auto px-5 py-5">
                                                <div className="mt-[40px]">
                                                    <h2 className="font-[700] text-white text-[55px] my-[20px]">$5</h2>
                                                    <button
                                                        className="bg-[#3E44DE] text-white px-8 w-full py-[10px] font-bold rounded-3xl hover:opacity-90 transition-all flex justify-center items-center disabled:opacity-50"
                                                        onClick={() => {
                                                            setPaymentData({
                                                                buttonId: 'submit-tool',
                                                                name: 'AI Bubbles (Submit Tool)',
                                                                price: 5,
                                                            })
                                                            setShowForm(true)
                                                        }}>
                                                        Pay
                                                    </button>
                                                    <h3 className="text-[18px] text-white mt-5">This includes:</h3>
                                                    <p className="text-[14px] mt-[10px] text-white">Listing of your Tool in the<br /> Ai Bubbles database</p>
                                                    <p className="mt-[10px] text-[14px] text-white mb-[20px]">Newsletter mention under <br />Featured Tools (incl. your Tool's <br />name and a direct link)</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <h3 className="text-[32px] font-[400] mb-2">Feature a Tool</h3>
                                            <div className="bg-gray-500 rounded-xl border-2 border-white max-w-[570px] mx-auto px-5 py-5">
                                                <div className="mt-[40px]">
                                                    <h2 className="font-[700] text-white text-[55px] my-[20px]">$10</h2>
                                                    <button
                                                        className="bg-[#3E44DE] text-white px-8 w-full py-[10px] font-bold rounded-3xl hover:opacity-90 transition-all flex justify-center items-center disabled:opacity-50"
                                                        onClick={() => {
                                                            setPaymentData({
                                                                buttonId: 'feature-tool',
                                                                name: 'AI Bubbles (Feature Tool)',
                                                                price: 10,
                                                            })
                                                            setShowForm(true)
                                                        }}>
                                                        Pay
                                                    </button>
                                                    <h3 className="text-[18px] text-white mt-5">This includes:</h3>
                                                    <p className="text-[14px] mt-[10px] text-white">Listing of your Tool in the<br /> Ai Bubbles database</p>
                                                    <p className="mt-[10px] text-[14px] text-white mb-[20px]">Newsletter mention under <br />Featured Tools (incl. your Tool's <br />name and a direct link)</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </CustomTabPanel>

                        <CustomTabPanel value={tabValue} index={1}>
                            <div className="bg-gray-500 rounded-xl border-2 border-white max-w-[570px] mx-auto px-5 py-5">
                                <div className="mt-[40px]">
                                    <h2 className="font-[700] text-white text-[55px] my-[20px]">$1</h2>
                                    <button
                                        className="bg-[#3E44DE] text-white px-8 w-full py-[10px] font-bold rounded-3xl hover:opacity-90 transition-all flex justify-center items-center disabled:opacity-50"
                                        onClick={() => {
                                            setPaymentData({
                                                buttonId: 'become-member',
                                                name: 'AI Bubbles (Become Member)',
                                                price: 1,
                                            })
                                            setShowForm(true)
                                        }}>
                                        Pay
                                    </button>
                                    <h3 className="text-[18px] text-white mt-5">This includes:</h3>
                                    <p className="text-[14px] mt-[10px] text-white">Listing of your Tool in the<br /> Ai Bubbles database</p>
                                    <p className="mt-[10px] text-[14px] text-white mb-[20px]">Newsletter mention under <br />Featured Tools (incl. your Tool's <br />name and a direct link)</p>
                                </div>
                            </div>
                        </CustomTabPanel>
                    </Box>
                </DialogContent>
            </Dialog> */}

        </Fragment>
    );
};

export default SubmitToolModal;