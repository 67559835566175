import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import GoogleLogin from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { configure, getUsersStateless, identity } from "deso-protocol";
import { loginFailure, loginSuccess } from "../../reducers/authReducer";
import {
  Button,
  Description,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { Close } from "@mui/icons-material";
import { nodeServerPath } from "../../constant/api";

const userLogin = async () => {
  const loggedInInfo = await identity.login();

  const userParams = {
    PublicKeysBase58Check: [loggedInInfo?.publicKeyBase58Check],
    SkipForLeaderboard: true,
  };
  const userDetails = await getUsersStateless(userParams);

  return { ...loggedInInfo, ...userDetails?.UserList[0] };
};

const AuthModal = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { currentTheme } = useSelector((state) => state.theme);

  useEffect(() => {
    configDeso();
  }, []);

  const configDeso = async () => {
    configure({
      spendingLimitOptions: {
        GlobalDESOLimit: 10000000,
        TransactionCountLimitMap: {
          SUBMIT_POST: "UNLIMITED",
        },
      },
    });
  };

  const responseGoogle = (response) => {
    if (response.error) {
      dispatch(loginFailure(response.error));
    } else {
      dispatch(loginSuccess(response.profileObj));
    }
  };

  const loginWithDeso = async () => {
    setLoading(true);
    try {
      const userDetails = await userLogin();
      if (userDetails) {
        const { data } = await handleSubmit(
          userDetails?.publicKeyAdded,
          "DESO"
        );
        if (data) {
          dispatch(loginSuccess(data?.user));
        }
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setOpen(false);
      setLoading(false);
    }
  };

  const handleSubmit = async (username, authMethod) => {
    const formData = {
      username,
      authMethod,
    };

    try {
      return await axios.post(`${nodeServerPath}/api/user`, formData);
    } catch (error) {
      if (error?.response?.status === 409) {
        dispatch(loginSuccess(JSON.parse(error?.response?.config?.data)));
      }
      return error;
    }
  };

  return (
    <Fragment>
      <Dialog
        onClose={() => setOpen(false)}
        open={open}
        as="div"
        className={`${currentTheme} relative z-[999] focus:outline-none`}
      >
        <DialogBackdrop className="fixed top-[77px] inset-0 bg-[#9C3CD6]/10 backdrop-blur" />
        <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <DialogPanel
              transition
              className="relative w-full max-w-md rounded-2xl p-10 backdrop-blur-xl shadow-filter transition duration-300 ease-out"
            >
              <Button
                onClick={() => setOpen(false)}
                className="absolute right-4 top-4 text-[var(--text-color-base)] hover:scale-110 transition-all"
              >
                <Close />
              </Button>

              <DialogTitle
                as="h3"
                className="text-2xl font-bold text-center text-[var(--text-color-base)]"
              >
                Welcome
              </DialogTitle>
              <Description className="opacity-80 text-center text-[var(--text-color-base)]">
                We are glad to see you back with us
              </Description>

              <div className="w-full my-5 h-px bg-[#9C3CD6]/40" />

              <Fragment>
                <div className="flex items-center justify-center text-[var(--text-color-base)]">
                  <p>Select one of the supported login options</p>
                </div>

                <div className="mt-5">
                  <GoogleLogin
                    disabled={loading}
                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                    pluginName="hello"
                    buttonText="Sign In with Google"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={"single_host_origin"}
                    className="w-full !flex justify-center !bg-white transition hover:!shadow-lg !text-black !font-normal !rounded-lg cursor-pointer !shadow !font-nunito !text-base !opacity-100  disabled:cursor-not-allowed"
                  />

                  <Button
                    disabled={loading}
                    onClick={loginWithDeso}
                    className="w-full flex items-center gap-6 justify-center transition hover:shadow-lg rounded-lg bg-white text-black px-3 ext-black py-[10px] text-base shadow cursor-pointer mt-3 font-nunito disabled:cursor-not-allowed"
                  >
                    {loading ? (
                      <Fragment>
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="inline mr-2 w-5 h-5 text-gray-200 animate-spin dark:text-gray-300"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="#9C3CD6"
                          ></path>
                        </svg>
                        In progress...
                      </Fragment>
                    ) : (
                      <Fragment>
                        <img
                          className="w-5"
                          src="https://c1.coinlore.com/img/decentralized-social.png"
                          alt="DESO"
                        />
                        Sign in with DESO
                      </Fragment>
                    )}
                  </Button>
                </div>
              </Fragment>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
      {/*<BootstrapDialog*/}
      {/*    onClose={() => setOpen(false)}*/}
      {/*    aria-labelledby="customized-dialog-title"*/}
      {/*    open={open}*/}
      {/*>*/}
      {/*    <DialogTitle sx={{m: 0, p: 2}} id="customized-dialog-title"*/}
      {/*                 className='flex justify-between items-start'>*/}
      {/*        <div>*/}
      {/*            <p className='text-3xl font-bold text-white mb-2'>Welcome</p>*/}
      {/*            <p className="text-[12px] leading-3">We are glad to see you back with us</p>*/}
      {/*        </div>*/}

      {/*        <IconButton*/}
      {/*            title='Close'*/}
      {/*            aria-label="close"*/}
      {/*            className='!p-0 opacity-70 hover:opacity-100 transition-all'*/}
      {/*            onClick={() => setOpen(false)}*/}
      {/*            sx={{*/}
      {/*                color: (theme) => theme.palette.grey[100],*/}
      {/*            }}*/}
      {/*        >*/}
      {/*            <CloseIcon fontSize="medium" si/>*/}
      {/*        </IconButton>*/}
      {/*    </DialogTitle>*/}

      {/*    <DialogContent>*/}
      {/*        <div className='my-5 min-w-[300px]'>*/}

      {/*            <div className='flex items-center justify-center gap-x-1 py-3'>*/}
      {/*                <p>Select one of the supported login options</p>*/}
      {/*            </div>*/}

      {/*            <div className="mt-3">*/}
      {/*                <GoogleLogin*/}
      {/*                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}*/}
      {/*                    pluginName='hello'*/}
      {/*                    buttonText="Sign In with Google"*/}
      {/*                    onSuccess={responseGoogle}*/}
      {/*                    onFailure={responseGoogle}*/}
      {/*                    cookiePolicy={'single_host_origin'}*/}
      {/*                    className="w-full !flex justify-center !bg-white transition hover:!shadow-lg !text-black !font-normal !rounded-lg cursor-pointer !shadow !font-nunito !text-base !opacity-100"*/}
      {/*                />*/}

      {/*                <button*/}
      {/*                    onClick={loginWithDeso}*/}
      {/*                    className='w-full flex items-center gap-1 justify-center transition hover:shadow-lg rounded-lg bg-white text-black px-3 ext-black py-[10px] text-base shadow cursor-pointer mt-3 font-nunito'>*/}
      {/*                    <img className='w-5 mr-4' src='https://c1.coinlore.com/img/decentralized-social.png'*/}
      {/*                         alt="DESO"/>*/}
      {/*                    <span>Sign in with DESO</span>*/}
      {/*                </button>*/}
      {/*            </div>*/}

      {/*        </div>*/}

      {/*    </DialogContent>*/}
      {/*</BootstrapDialog>*/}
    </Fragment>
  );
};

export default AuthModal;
