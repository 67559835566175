import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    preferenceDate: null
};

const preferenceSlice = createSlice({
    name: 'preference',
    initialState,
    reducers: {
        setPreferenceDate: (state, action) => {
            state.preferenceDate = action.payload;
        },
    },
});

export const {setPreferenceDate} = preferenceSlice.actions;

export default preferenceSlice.reducer;
