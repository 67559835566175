import chroma from "chroma-js";

export const formatSentence = (sentence) => {
  // Convert to lowercase
  const lowercaseSentence = sentence?.toLowerCase();

  // Replace spaces with hyphens
  const formattedSentence = lowercaseSentence?.replace(/\s+/g, "-");

  return formattedSentence;
};

export const sanitizeToolName = (toolName) => {
  // Replace spaces with underscores
  let sanitized = toolName.replace(/\s+/g, "_");
  // Remove or replace invalid characters, keeping only letters, digits, hyphens, and underscores
  sanitized = sanitized.replace(/[^a-zA-Z0-9-_]/g, "");
  return sanitized;
};

export const generateGradients = (baseColor, categoryCount) => {
  let scale = chroma.scale(baseColor).mode("lch").colors(categoryCount);
  return scale.map((color) => chroma(color).brighten(1).saturate(1).hex());
};

// Function to sanitize category names
export const sanitizeCategoryName = (categoryName) => {
  if (categoryName === undefined || categoryName === null) {
    return "xxx";
  }
  // Replace spaces with underscores
  let sanitized = categoryName.replace(/\s+/g, "_");
  // Remove or replace invalid characters, keeping only letters, digits, hyphens, and underscores
  sanitized = sanitized.replace(/[^a-zA-Z0-9-_]/g, "");
  return sanitized;
};

export const formatNumber = (value) => {
  if (value === null || value === undefined) return "0";
  if (typeof value !== "number") value = Number(value);

  const suffixes = ["", "K", "M", "B", "T"]; // K = Thousand, M = Million, B = Billion, T = Trillion
  const tier = Math.floor(Math.log10(Math.abs(value)) / 3);

  if (tier === 0) return value.toString(); // Numbers less than 1000 are returned as is.

  const scaledValue = value / Math.pow(10, tier * 3);
  const suffix = suffixes[tier];

  // Limit to 1 decimal place if necessary
  return `${scaledValue.toFixed(1).replace(/\.0$/, "")}${suffix}`;
};
