import React from 'react'
import { useNavigate } from "react-router-dom";

export default function CancelPage() {
    const navigate = useNavigate();

    return (
        <div className='w-full h-[50vh] flex flex-col justify-center items-center'>
            <p className='mb-5 text-red-500 text-xl font-bold'>Your payment was canceled!</p>
            <button
                className="bg-[#483aa7] text-white px-8 py-[10px] font-bold rounded-3xl hover:opacity-90 transition-all"
                onClick={() => navigate('/')}>
                Go to Home
            </button>
        </div>
    )
}
