import React, { useEffect, useState } from "react";
import { getRecentTools } from "../../utils/api/tool";
import { useSelector } from "react-redux";
import usePagination from "../../hooks/usePagination";
import Pagination from "../../components/snippets/pagination";
import { Fragment } from "react";
import ToolBubble from "../../components/bubbles/tool-bubble";
import SingleForYouItem from "../../components/snippets/tool-card";

const RecentPage = () => {
  const user = useSelector((state) => state.auth.user);
  const { showOnlyTable } = useSelector((state) => state.global);

  // Constants
  const BUBBLES_PER_PAGE = user?.bubblesPerPage || 50;

  // States
  const [loading, setLoading] = useState(true);
  const [tools, setTools] = useState([]);
  const [dataSets, setDataSets] = useState({});
  const [blueprints, setBlueprints] = useState({
    totalTools: 0,
    totalPages: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);

  // Pagination
  const { onClickLeftPage, onClickRightPage } = usePagination(
    currentPage,
    setCurrentPage
  );

  // Fetch Tools
  const fetchTools = async (page) => {
    if (dataSets[page]) {
      // Use cached data
      const { tools, blueprints } = dataSets[page];
      setTools(tools);
      setBlueprints(blueprints);
      return;
    }

    // Fetch new data
    try {
      const params = { page, perPage: BUBBLES_PER_PAGE };
      const res = await getRecentTools(
        setTools,
        setBlueprints,
        params,
        user?.accessToken
      );

      // Cache the result
      setDataSets((prev) => ({
        ...prev,
        [page]: res,
      }));
    } finally {
      setLoading(false);
    }
  };

  // Effect to fetch tools on page change
  useEffect(() => {
    fetchTools(currentPage);
  }, [currentPage]);

  return (
    <Fragment>
      {loading ? (
        <div className="p-5 text-center">Loading Tools...</div>
      ) : tools.length ? (
        !showOnlyTable && <ToolBubble tools={tools} loading={loading} />
      ) : (
        <div className="w-full h-full flex items-center justify-center">
          <h4 className="text-base text-[var(--text-color-base)]">
            No tools found!
          </h4>
        </div>
      )}

      <div className="max-w-4xl mx-auto grid grid-flow-col gap-10 text-[var(--text-color-base)] py-10 px-5">
        {/* Main Content */}
        <div className="col-span-10">
          <SingleForYouItem
            tools={tools}
            loading={loading}
            blueprints={blueprints}
            setParams={() => {} /* No longer needed */}
          />
          {loading && <div className="p-5 text-center">Loading Tools...</div>}
        </div>

        {/* Pagination */}
        {!showOnlyTable && blueprints.totalTools > BUBBLES_PER_PAGE && (
          <div className="fixed right-10 bottom-10">
            <Pagination
              currentPage={currentPage}
              onClickLeftPage={onClickLeftPage}
              onClickRightPage={onClickRightPage}
              itemsPerPage={BUBBLES_PER_PAGE}
              totalValue={blueprints.totalTools}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default RecentPage;
