import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Button } from "@headlessui/react";
import { FacebookRounded, Instagram, Star, Twitter } from "@mui/icons-material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  FacebookShareButton,
  InstapaperShareButton,
  TwitterShareButton,
} from "react-share";
import clsx from "clsx";
import { getSimilarTool, getToolsDetails } from "../../utils/api/tool";
import SimilarTools from "../../components/snippets/similar-tools";
import SocialShare from "../../components/snippets/social-share";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function DetailsPage() {
  const [loading, setLoading] = useState(false);
  const [toolInfo, setToolInfo] = useState("");
  const [similarTools, setSimilarTools] = useState([]);
  const query = useQuery();
  const hash = query.get("hash");
  const navigate = useNavigate();

  useEffect(() => {
    if (hash) {
      fetchToolDetails();
      fetchSimilarTools();
    }
  }, [hash]);

  const fetchToolDetails = async () => {
    try {
      await getToolsDetails(hash, setToolInfo);
    } finally {
      setLoading(false);
    }
  };

  const fetchSimilarTools = async () => {
    try {
      await getSimilarTool(setSimilarTools, { hash });
    } finally {
      setLoading(false);
    }
  };

  const handelBack = () => {
    navigate("/");
  };

  const shareUrl = window.location.href;

  return (
    <div className="w-full bg-[var(--bg-color)] relative">
      <Button
        title="Back"
        className="absolute left-8 top-2 flex items-center justify-center rounded-full bg-[var(--primary-color)] w-10 aspect-square text-sm text-[var(--text-color-dark)] data-[hover]:bg-[var(--primary-color)] data-[hover]:opacity-80 transition-all data-[active]:bg-[var(--primary-color)]"
        onClick={handelBack}
      >
        <ArrowBackIosIcon fontSize="small" className="ml-1.5" />
      </Button>
      <div className={`container max-w-6xl p-14 mx-auto bg-repeat`}>
        <div className="flex items-center justify-center">
          {loading ? (
            <p className="text-[var(--text-color-base)]">Loading...</p>
          ) : (
            <div className="mx-auto shadow-[var(--shadow-color)] relative w-full max-w-2xl rounded-2xl p-10 shadow-md backdrop-blur-2xl transition duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0">
              <div className="w-full flex flex-col items-center gap-3">
                <div
                  style={{ backgroundImage: `url(/assets/bubble_dark.svg)` }}
                  className="w-40 h-40 rounded-full backdrop-blur-xl shadow-filter bg-center bg-no-repeat bg-cover text-[var(--text-color-base)] flex flex-col items-center justify-center gap-2"
                >
                  <h4 className="text-sm font-normal text-center">
                    Total Global Visit
                  </h4>
                  <h1 className="text-2xl/5 font-bold text-center">
                    {toolInfo?.stats?.["total-visits"]?.toLocaleString() || 0}
                  </h1>
                </div>

                <table className="w-full table-auto text-lg font-medium text-[var(--text-color-base)] border-spacing-y-3 border-separate">
                  <tbody>
                    <tr>
                      <td className="w-[120px] opacity-70">Name:</td>
                      <td>{toolInfo.name}</td>
                    </tr>
                    <tr>
                      <td className="w-[120px] opacity-70">Rank:</td>
                      <td>
                        <div className="w-max px-2 py-1 bg-[var(--bg-color)] rounded">
                          {toolInfo?.rank?.upvotes}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-[120px] opacity-70">Use case:</td>
                      <td>{toolInfo?.use_case}</td>
                    </tr>
                    <tr>
                      <td className="w-[120px] opacity-70 align-top">
                        Description:
                      </td>
                      <td className="break-all">{toolInfo.description}</td>
                    </tr>
                    <tr>
                      <td className="w-[120px] opacity-70 align-top">Link:</td>
                      <td className="break-all">
                        <a
                          className="underline"
                          href={toolInfo.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {toolInfo.url}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="w-full my-2 h-px bg-[var(--border-color)]" />

                <div className="flex items-center justify-center">
                  <div className="flex items-center gap-2">
                    <Star className="text-[var(--primary-color)] cursor-pointer hover:scale-110 transition-all" />
                    <Star className="text-[var(--primary-color)] cursor-pointer hover:scale-110 transition-all" />
                    <Star className="text-[var(--primary-color)] cursor-pointer hover:scale-110 transition-all" />
                    <Star className="text-[var(--primary-color)] cursor-pointer hover:scale-110 transition-all" />
                    <Star className="text-gray-300  cursor-pointer hover:scale-110 transition-all" />
                  </div>
                  <p className="ms-1 text-sm font-medium text-gray-500 dark:text-gray-400">
                    4 out of 5
                  </p>
                </div>

                <div className="w-full my-2 h-px bg-[var(--border-color)]" />

                <div className="w-max mx-auto">
                  <SocialShare shareUrl={shareUrl} />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="pt-16">
          <SimilarTools similarTools={similarTools} />
        </div>
      </div>
    </div>
  );
}

export default DetailsPage;
