export const bubbleColor = {
  // Dark theme colors
  darkFontColor: '#fff',
  darkShadow: 'rgba(0, 0, 0, 0.4)',
  darkShadowStart: 'rgba(156, 155, 255, 0.05)',
  darkShadowMiddle: 'rgba(192, 155, 255, 0.1)',
  darkShadowEnd: 'rgba(192, 155, 255, 0.5)',
  darkHoverShadow: 'rgba(0, 0, 0, 0.8)',
  darkHoverShadowStart: 'rgba(64, 255, 186, 0.05)',
  darkHoverShadowMiddle: 'rgba(64, 255, 186, 0.1)',
  darkHoverShadowEnd: 'rgba(64, 255, 186, 0.5)',


  // Light theme colors
  lightFontColor: '#222',
  lightShadow: 'rgba(0, 0, 0, 0.4)',
  lightShadowStart: 'rgba(64, 255, 186, 0.05)',
  lightShadowMiddle: 'rgba(64, 255, 186, 0.1)',
  lightShadowEnd: 'rgba(64, 255, 186, 0.5)',
  lightHoverShadow: 'rgba(0, 0, 0, 0.8)',
  lightHoverShadowStart: 'rgba(156, 155, 255, 0.05)',
  lightHoverShadowMiddle: 'rgba(192, 155, 255, 0.1)',
  lightHoverShadowEnd: 'rgba(192, 155, 255, 0.5)',
};