import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentCategory,
  setShowOnlyTable,
} from "../../reducers/globalReducer";
import { useNavigate } from "react-router-dom";
import { Button } from "@headlessui/react";
import GlobalSearch from "../../components/forms/global-search";

export default function HeaderLeft() {
  const dispatch = useDispatch();
  const { currentTheme } = useSelector((state) => state.theme);
  const navigate = useNavigate();

  const clearIndex = () => {
    dispatch(setCurrentCategory(null));
    dispatch(setShowOnlyTable(false));
    navigate("/");
  };

  return (
    <div className="flex-1 flex items-center gap-5">
      <Button onClick={clearIndex} className="h-auto w-[220px]">
        <img
          src={
            currentTheme === "light-theme"
              ? "/assets/logo-light.svg"
              : "/assets/logo-dark.svg"
          }
          className="w-full h-full"
          alt="Logo"
        />
      </Button>
      <GlobalSearch />
    </div>
  );
}
