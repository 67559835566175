import React, { useState } from "react";
import AuthModal from "../../components/modals/auth-modal";
import { useDispatch, useSelector } from "react-redux";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import {
  setShowOnlyTable,
} from "../../reducers/globalReducer";
import ThemeSwitcher from "../../components/forms/theme-switcher";
import NavbarItems from "./navbar-items";
import HeaderLeft from "./header-left";
import AvatarMenus from "./avatar-menus";
import { useLocation } from "react-router-dom";

export default function Header() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const { user } = useSelector((state) => state.auth);
  const { showOnlyTable } = useSelector((state) => state.global);
  const [openModal, setOpenModal] = useState(false);
  const { currentCategory } = useSelector((state) => state.global);

  const toggleTableShow = () => {
    dispatch(setShowOnlyTable(!showOnlyTable));
  };  

  return (
    <header className="w-full flex justify-between items-center gap-5 bg-[var(--bg-color)] py-[16px] px-3 md:px-8 border-b border-[var(--border-color)]">
      {/* Header Left Part */}
      <HeaderLeft />

      <h4 className="text-[var(--text-color-base)] whitespace-nowrap">
        {currentCategory}
      </h4>

      <div className="flex-1 flex items-center justify-end gap-4 transition-all">
        
        {/* Nav Items */}
        <NavbarItems />

        <button
          onClick={toggleTableShow}
          disabled={!currentCategory && location.pathname === "/"}
          className={`text-[var(--text-color-base)] transition-all disabled:opacity-20 disabled:cursor-not-allowed ${
            showOnlyTable ? "!text-[var(--primary-color)]" : ""
          }`}
        >
          <BackupTableIcon />
        </button>

        <ThemeSwitcher />

        {!isAuthenticated || user?.authMethod === "LOCAL" ? (
          <button
            onClick={() => setOpenModal(true)}
            className="flex items-center text-white font-semibold px-[23px] py-[10px] rounded-full bg-[var(--primary-color)]"
          >
            <img src="/assets/google-icon.png" alt="Google Logo" />
            <span className="ml-[10px]">Sign in</span>
          </button>
        ) : (
          <AvatarMenus />
        )}

        {/* Auth Modal */}
        <AuthModal open={openModal} setOpen={setOpenModal} />

      </div>
    </header>
  );
}
