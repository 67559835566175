import React from 'react'
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import HomePage from '../pages/home';
import SuccessPage from '../pages/success';
import CancelPage from '../pages/cancel';
import Layout from '../layouts';
import DetailsPage from '../pages/details';
import FavoritesPage from '../pages/[userId]/favorites';
import SettingsPage from "../pages/user/settings";
import PreferencesPage from "../pages/user/preferences";
import ErrorPage from "../pages/404";
import ForYouPage from '../pages/for-you';
import PopularPage from '../pages/popular';
import RecentPage from '../pages/recent';

const router = createBrowserRouter([
    {
        // parent route component
        path: '/',
        element: <Layout/>,
        errorElement: <ErrorPage/>,

        // child route components
        children: [
            {
                path: "/",
                element: <HomePage/>,
            },
            {
                path: "/for-you",
                element: <ForYouPage/>,
            },
            {
                path: "/popular",
                element: <PopularPage/>,
            },
            {
                path: "/recent",
                element: <RecentPage/>,
            },
            {
                path: "/success",
                element: <SuccessPage/>,
            },
            {
                path: "/cancel",
                element: <CancelPage/>,
            },
            {
                path: ":name/details",
                element: <DetailsPage/>,
            },
            {
                path: ":userId/favorites",
                element: <FavoritesPage/>,
            },
            {
                path: ":userId/settings",
                element: <SettingsPage/>,
            },
            {
                path: ":userId/preferences",
                element: <PreferencesPage/>,
            },
        ],
    },
])

export default function AllRoutes() {
    return (
        <RouterProvider router={router}/>
    )
}
