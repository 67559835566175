import TopBar from "../../components/snippets/top-bar";
import SubmitToolModal from "../../components/modals/submit-tool-modal";
import {useSelector} from "react-redux";

import Tools from "./tools";
import Categories from "./categories";

function HomePage() {
    const {currentCategory} = useSelector((state) => state.global);

    return (
        <div className="relative">
            <TopBar/>

            {currentCategory ? (
                <Tools/>
            ) : (
                <Categories/>
            )}
            <SubmitToolModal/>
        </div>
    )
}

export default HomePage;

