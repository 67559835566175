import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {setTheme} from '../../reducers/themeReducer';
import {DarkMode, WbSunny} from "@mui/icons-material";
import clsx from "clsx";
import {Switch} from "@headlessui/react";

const ThemeSwitcher = () => {
    const {currentTheme} = useSelector((state) => state.theme);
    const dispatch = useDispatch();

    return (
        <Switch
            checked={currentTheme && currentTheme === 'dark-theme'}
            onChange={(value) => {
                dispatch(setTheme(value ? "dark-theme" : "light-theme"));
            }}
            className="group relative flex h-7 w-14 cursor-pointer rounded-full bg-gray-200 p-1 transition-colors duration-200 ease-in-out focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-white/10"
        >
            <div className="absolute left-0 top-0 px-2 w-full h-full flex items-center justify-between">
                <WbSunny fontSize="10" className={clsx(
                    'text-[var(--text-color-base)] opacity-20 transition-all',
                    `${currentTheme === 'dark-theme' ? 'visible' : 'invisible'}`
                )}/>
                <DarkMode fontSize="10" className={clsx(
                    'text-[var(--text-color-base)] opacity-20 transition-all',
                    `${currentTheme === 'light-theme' ? 'visible' : 'invisible'}`
                )}/>
            </div>
            <span
                aria-hidden="true"
                className="inline-flex items-center justify-center overflow-hidden text-[var(--primary-color)] pointer-events-none h-full aspect-square translate-x-0 rounded-full bg-white ring-0 shadow-lg transition duration-200 ease-in-out group-data-[checked]:translate-x-7"
            >
                      {currentTheme === 'dark-theme' ? (
                          <DarkMode fontSize="10"/>
                      ) : (
                          <WbSunny fontSize="10"/>
                      )}
                  </span>
        </Switch>
    );
};

export default ThemeSwitcher;
