import axios from 'axios';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { loginSuccess } from '../../reducers/authReducer';
import { nodeServerPath } from '../../constant/api';

export default function SuccessPage() {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const navigate = useNavigate();
    const query = new URLSearchParams(window.location.search);
    const sessionId = query.get('sessionId');
    const formData = JSON.parse(localStorage.getItem('formData'));

    useEffect(() => {
        if (sessionId) {
            const submitForm = async () => {
                try {
                    let response = null
                    if (!user) {
                        response = await axios.post(`${nodeServerPath}/api/user`, { authMethod: 'LOCAL' });
                        await axios.patch(`${nodeServerPath}/api/user/${response?.data?.user?._id}`, { paymentInfo: { ...formData } });
                        dispatch(loginSuccess(response?.data?.user))
                        localStorage.removeItem('formData');
                    } else {
                        await axios.patch(`${nodeServerPath}/api/user/${user?._id}`, { paymentInfo: { ...formData } });
                        localStorage.removeItem('formData');
                    }
                } catch (error) {
                    console.error('User update failed:', error);
                }
            };
            submitForm();
        }
    }, [sessionId, nodeServerPath, formData]);

    return (
        <div className='w-full h-[50vh] flex flex-col justify-center items-center'>
            <p className='mb-5 text-green-600 text-xl font-bold'>Your payment was successful!</p>
            <button
                className="bg-[#483aa7] text-white px-8 py-[10px] font-bold rounded-3xl hover:opacity-90 transition-all"
                onClick={() => navigate('/')}>
                Go to Home
            </button>
        </div>
    )
}
