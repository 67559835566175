import { formatNumber } from "..";
import { bubbleColor } from "../../constant/bubble";

export const resizeCanvas = (canvas, padding) => {
  canvas.width = window.innerWidth - 2 * padding;
  canvas.height = window.innerHeight - 2 * padding;
};

export const getMousePos = (canvas, evt) => {
  const rect = canvas.getBoundingClientRect();
  return {
    x: (evt.clientX - rect.left) * (canvas.width / rect.width),
    y: (evt.clientY - rect.top) * (canvas.height / rect.height),
  };
};

export const isInsideBubble = (pos, bubble) => {
  const dist = Math.sqrt((pos.x - bubble.x) ** 2 + (pos.y - bubble.y) ** 2);
  return dist < bubble.size;
};

const easeOutQuad = (t) => t * (2 - t);

export const drawBubble = (context, bubble, theme) => {
  context.beginPath();

  const easingFactor = 0.04; // Smoother transition

  // Calculate current size based on target size
  if (bubble.targetSize === undefined) {
    bubble.targetSize = bubble.size;
  }
  bubble.currentSize = bubble.currentSize || bubble.size;
  bubble.currentSize += (bubble.targetSize - bubble.currentSize) * easingFactor;

  // Determine if the bubble is blinking
  const isBlinking = bubble.isBlinking;
  const blinkFactor = isBlinking ? Math.sin(Date.now() / 200) * 0.5 + 1 : 1;

  // Set up colors for the fill based on hover and theme
  const isHovered = bubble.targetSize > bubble.size;

  const hoverShadowStart = theme === 'light-theme' ? bubbleColor.lightHoverShadowStart : bubbleColor.darkHoverShadowStart;
  const hoverShadowMiddle = theme === 'light-theme' ? bubbleColor.lightHoverShadowMiddle : bubbleColor.darkHoverShadowMiddle;
  const hoverShadowEnd = theme === 'light-theme' ? bubbleColor.lightHoverShadowEnd : bubbleColor.darkHoverShadowEnd;
  const hoverShadow = theme === 'light-theme' ? bubbleColor.lightHoverShadow : bubbleColor.darkHoverShadow;

  const gradient = context.createRadialGradient(
    bubble.x, bubble.y, bubble.currentSize * 0.3,
    bubble.x, bubble.y, bubble.currentSize
  );

  gradient.addColorStop(0, isHovered ? hoverShadowStart : (theme === 'light-theme' ? bubbleColor.lightShadowStart : bubbleColor.darkShadowStart));
  gradient.addColorStop(0.7, isHovered ? hoverShadowMiddle : (theme === 'light-theme' ? bubbleColor.lightShadowMiddle : bubbleColor.darkShadowMiddle));
  gradient.addColorStop(1, isHovered ? hoverShadowEnd : (theme === 'light-theme' ? bubbleColor.lightShadowEnd : bubbleColor.darkShadowEnd));

  // Outer shadow
  context.shadowColor = isHovered ? hoverShadow : (theme === 'light-theme' ? bubbleColor.lightShadow : bubbleColor.darkShadow);
  context.shadowBlur = isHovered ? 30 : 20;
  context.shadowOffsetX = isHovered ? 7 : 5;
  context.shadowOffsetY = isHovered ? 7 : 5;

  // Draw the filled bubble
  context.arc(bubble.x, bubble.y, bubble.currentSize, 0, Math.PI * 2, false);
  context.fillStyle = gradient;
  context.fill();
  context.closePath();

  // Draw the blinking border if the bubble is blinking
  if (isBlinking) {
    context.beginPath();
    context.arc(bubble.x, bubble.y, bubble.currentSize + 5, 0, Math.PI * 2, false); // Slightly larger for the border
    context.lineWidth = 5 * blinkFactor; // Pulsing border thickness
    context.strokeStyle = theme === 'light-theme' ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.8)'; // Bright border
    context.stroke();
    context.closePath();
  }

  // Reset shadow properties for text drawing
  context.shadowColor = 'transparent';
  context.shadowBlur = 0;
  context.shadowOffsetX = 0;
  context.shadowOffsetY = 0;

  // Draw text inside the bubble
  if (typeof bubble.text === 'string') {
    const maxTextWidth = bubble.currentSize * 3; // Set a max width based on bubble size
    const lines = bubble.text.split('\n');
    context.fillStyle = theme === 'light-theme' ? bubbleColor.lightFontColor : bubbleColor.darkFontColor;
    context.font = `${bubble.currentSize / 2.5}px Arial`;
    context.textAlign = 'center';
    context.textBaseline = 'middle';

    // Function to truncate text and add ellipsis
    const truncateText = (text, maxWidth) => {
      let truncatedText = text;
      while (context.measureText(truncatedText).width > maxWidth) {
        truncatedText = truncatedText.slice(0, -1);
      }
      return truncatedText + '...';
    };

    let firstLine = lines[0];
    if (context.measureText(firstLine).width > maxTextWidth) {
      firstLine = truncateText(firstLine, maxTextWidth);
    }
    context.fillText(firstLine, bubble.x, bubble.y - bubble.currentSize / 4);

    if (lines[1]) {
      context.font = `${bubble.currentSize / 3}px Arial`;
      let secondLine = lines[1];
      if (context.measureText(secondLine).width > maxTextWidth) {
        secondLine = truncateText(secondLine, maxTextWidth);
      }
      context.fillText(secondLine, bubble.x, bubble.y + bubble.currentSize / 6);
    }
  }
};

export const drawBubbles = (context, bubbles, theme) => {
  context.clearRect(0, 0, context.canvas.width, context.canvas.height);
  bubbles.forEach(bubble => drawBubble(context, bubble, theme));
};

export const createBubbles = (type, categories, canvas, baseBubbleSize, padding, theme) => {
  const bubbles = [];

  const screenRatio = Math.min(window.innerWidth / 1920, window.innerHeight / 1080);
  const dynamicBaseBubbleSize = baseBubbleSize * screenRatio; // Adjust size based on screen ratio
  const minBubbleSize = 20; // Set a minimum bubble size to avoid too small bubbles

  const maxRankOrCount = Math.max(
    ...categories.map((category) =>
      type === 'CATEGORY' ? category?.count || 0 : category?.stats?.['total-visits'] || 1
    )
  );

  categories.forEach((category, index) => {
    let x, y, collision;
    let attempts = 0;

    const size = Math.max(
      minBubbleSize,
      dynamicBaseBubbleSize +
      ((type === 'CATEGORY' ? category?.count : category?.stats?.['total-visits'] || 1) / maxRankOrCount) *
      dynamicBaseBubbleSize
    );

    do {
      collision = false;
      x = Math.random() * (canvas.width - 2 * padding - 2 * size) + padding + size;
      y = Math.random() * (canvas.height - 2 * padding - 2 * size) + padding + size;

      // Check for collisions with existing bubbles
      for (let bubble of bubbles) {
        const dist = Math.sqrt((x - bubble.x) ** 2 + (y - bubble.y) ** 2);
        if (dist < size + bubble.size + 20) {
          collision = true;
          break;
        }
      }

      if (!collision) {
        const targetX = x + (Math.random() * 100 - 50);
        const targetY = y + (Math.random() * 100 - 50);

        // Assign name, text, and color properties based on type
        const bubbleId = category._id
        const name = type === 'CATEGORY' ? category._id : category.name;
        const text = type === 'CATEGORY' ? `${category._id}\n ${category.count}` : `${category.name}\n ${formatNumber(category?.stats?.['total-visits']) || '---'}`;

        const color = type === 'CATEGORY'
          ? (theme === 'light-theme' ? 'hsl(200, 100%, 50%)' : 'hsl(200, 90%, 10%)')
          : (theme === 'light-theme' ? 'hsl(80, 80%, 40%)' : 'hsl(120, 70%, 70%)');

        // Add name and other properties to the bubble
        bubbles.push({ x, y, size, color, text, targetX, targetY, name, _id: bubbleId, isBlinking: false });
      }

      attempts++;
      if (attempts > 50) break;
    } while (collision);
  });

  return bubbles;
};




const resolveCollisions = (bubbles) => {
  const resolveCollision = (b1, b2) => {
    const dx = b2.x - b1.x;
    const dy = b2.y - b1.y;
    const dist = Math.sqrt(dx * dx + dy * dy);
    const overlap = b1.size + b2.size + 20 - dist;

    if (overlap > 0) {
      const angle = Math.atan2(dy, dx);
      const moveX = Math.cos(angle) * overlap / 2;
      const moveY = Math.sin(angle) * overlap / 2;

      b1.x -= moveX;
      b1.y -= moveY;
      b2.x += moveX;
      b2.y += moveY;
    }
  };

  for (let i = 0; i < bubbles.length; i++) {
    for (let j = i + 1; j < bubbles.length; j++) {
      resolveCollision(bubbles[i], bubbles[j]);
    }
  }
};

export const animateBubbles = (bubbles, context, startTime, duration, drawBubbles, onComplete, theme) => {
  const animate = () => {
    const currentTime = Date.now();
    const elapsed = currentTime - startTime;
    const progress = Math.min(elapsed / duration, 1);
    const easedProgress = easeOutQuad(progress);

    bubbles.forEach(bubble => {
      const deltaX = bubble.targetX - bubble.x;
      const deltaY = bubble.targetY - bubble.y;
      bubble.x += deltaX * easedProgress;
      bubble.y += deltaY * easedProgress;
    });

    resolveCollisions(bubbles);
    drawBubbles(context, bubbles, theme);

    if (progress < 1) {
      requestAnimationFrame(animate);
    } else if (onComplete) {
      onComplete(); // Call the callback function once the animation completes
    }
  };

  requestAnimationFrame(animate);
};

