import axios from "axios";
import { toast } from "react-toastify";
import { loginSuccess } from "../../reducers/authReducer";
import { nodeServerPath } from "../../constant/api";

export const fetchCategories = async (
  setCategories,
  setBlueprints,
  { page, perPage },
  accessToken = null
) => {
  try {
    const url = `${nodeServerPath}/api/categories?page=${page}&perPage=${perPage}`;

    const { data } = await axios.get(
      url,
      accessToken && { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    const { categories, ...blueprints } = data;

    const filteredCategories = categories.filter((item) => item !== null);

    setBlueprints(blueprints);
    setCategories(filteredCategories);

    return { categories: filteredCategories, blueprints };
  } catch (error) {
    // Log the error and provide detailed error message if available
    console.error(
      "Error fetching categories:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const addToFavourite = async (
  user,
  toolId,
  dispatch,
  setToolModalOpen
) => {
  try {
    const url = `${nodeServerPath}/api/${user._id}/favorites`;
    await axios.post(
      url,
      { toolId },
      { headers: { Authorization: `Bearer ${user.accessToken}` } }
    );
    toast("Added to favorite list.");
    setToolModalOpen(false);
  } catch (error) {
    if (error?.response?.status === 409) {
      dispatch(loginSuccess(JSON.parse(error?.response?.config?.data)));
    }
    toast.error(error?.response.data.message);
  }
};

export const getFavourites = async (
  userId,
  setFavorites,
  setBlueprints,
  accessToken,
  { page, perPage }
) => {
  try {
    const url = `${nodeServerPath}/api/${userId}/favorites?page=${page}&perPage=${perPage}`;
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken || ""}` },
    });
    const { favorites, ...blueprints } = data;
    if (favorites) {
      setFavorites(favorites);
    }
    setBlueprints(blueprints);
    return { favorites, blueprints };
  } catch (error) {
    console.error("Error fetching favourite list:", error);
  }
};

export const removeFavourite = async (user, id) => {
  const url = `${nodeServerPath}/api/${user?._id}/favorites/${id}`;
  await axios
    .delete(url, {
      headers: { Authorization: `Bearer ${user.accessToken || ""}` },
    })
    .then(async () => {
      toast("Favorite item deleted successfully");
    })
    .catch((e) => {
      toast.error(e?.response?.data?.message);
    });
};

export const fetchPreferences = async (accessToken, setPreferences) => {
  const url = `${nodeServerPath}/api/preferences`;
  try {
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    const { data } = response;
    setPreferences(data.preferences);
    return data.preferences; // Return fetched preferences, in case needed
  } catch (error) {
    console.error(
      "Error fetching preferences:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const postPreferences = async (accessToken, preference) => {
  try {
    const url = `${nodeServerPath}/api/preferences`;
    const response = await axios.post(
      url,
      { preferenceCategories: [preference] },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error posting preferences:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const fetchMyPreferences = async (accessToken, setPreferences) => {
  try {
    const url = `${nodeServerPath}/api/myPreferences`;
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    const { data } = response;
    setPreferences(data.preferences);
    return data.preferences;
  } catch (error) {
    console.error(
      "Error fetching my preferences:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const deleteMyPreferences = async (accessToken, id) => {
  try {
    const url = `${nodeServerPath}/api/myPreferences/${id}`;
    const response = await axios.delete(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error delete preference:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const getSearchResults = async (searchQuery, setSearchItems) => {
  try {
    const url = `${nodeServerPath}/api/search-result?query=${searchQuery}`;
    const { data } = await axios.get(url);
    const { results, ...blueprints } = data;
    setSearchItems(results);
    return { results, blueprints };
  } catch (error) {
    console.error("Error fetching search results:", error);
  }
};
