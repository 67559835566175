import {useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {getFavourites} from "../../../utils/api";
import {useSelector} from "react-redux";
import FavoritesCards from "./FavoritesCards";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {Button} from "@headlessui/react";

function FavoritesPage() {
    const [loading, setLoading] = useState(false);
    const [initLoading, setInitLoading] = useState(false);

    const [favorites, setFavorites] = useState([]);
    const {user} = useSelector(state => state.auth);
    const {userId} = useParams();
    const navigate = useNavigate();

    const [blueprints, setBlueprints] = useState({
        page: 0,
        perPage: 0,
        totalPages: 0
    });
    const [params, setParams] = useState({
        page: 1,
        perPage: user?.bubblesPerPage || 50,
    });

    const handelSetFavorites = (data) => {
        setFavorites([...favorites, ...data]);
    }

    useEffect(() => {
        if (userId) {
            if (favorites.length) {
                setLoading(true)
            } else {
                setInitLoading(true)
            }
            getFavourites(userId, handelSetFavorites, setBlueprints, user?.accessToken, params)
                .finally(() => {
                    setLoading(false)
                    setInitLoading(false)
                })
        } else {
            setFavorites([])
        }
    }, [userId, params])

    const handelBack = () => {
        navigate('/')
    }

    return (
        <div className="relative text-[var(--text-color-base)]">
            <div className="sticky left-0 top-0 backdrop-blur z-50">
                <div className="max-w-xl mx-auto flex items-center gap-5 py-2">
                    <Button title="Back"
                            className="flex items-center justify-center rounded-full bg-[var(--primary-color)] w-10 aspect-square text-sm text-[var(--text-color-dark)] data-[hover]:bg-[var(--primary-color)] data-[hover]:opacity-80 transition-all data-[active]:bg-[var(--primary-color)]"
                            onClick={handelBack}>
                        <ArrowBackIosIcon fontSize='small' className="ml-1.5"/>
                    </Button>
                    <h1 className="text-4xl font-semibold text-[var(--text-color-base)]">Favorites</h1>
                </div>
            </div>
            <div className="max-w-xl mx-auto">
                {initLoading ? (
                    <div className="w-full h-full min-h-[50vh] flex items-center justify-center">
                        <h4 className="text-base text-[var(--text-color-base)]">
                            Loading...
                        </h4>
                    </div>
                ) : (
                    <FavoritesCards
                        tools={favorites}
                        setParams={() => {
                            setParams({...params, page: params.page + 1});
                        }}
                        blueprints={blueprints}
                        loading={loading}/>
                )}
            </div>
        </div>
    );
}

export default FavoritesPage;

