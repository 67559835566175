import { useCallback } from "react";

const usePagination = (currentPage, setCurrentPage) => {
    const onClickLeftPage = useCallback(() => {
        if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
        }
    }, [currentPage, setCurrentPage]);
 
    const onClickRightPage = useCallback(() => {
        setCurrentPage(prev => prev + 1);
    }, [setCurrentPage]);
 
    return { onClickLeftPage, onClickRightPage, currentPage };
};

export default usePagination;