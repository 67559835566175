import {createSlice} from '@reduxjs/toolkit';
import {DEFAULT_FILTER_LIST} from "../data/filterList";

const initialState = {
    selectedTool: null,
    filters: [...DEFAULT_FILTER_LIST],
    selectedFilter: null,
};

const themeSlice = createSlice({
    name: 'tools',
    initialState,
    reducers: {
        updateSelectedTool: (state, action) => {
            state.selectedTool = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = [...state.filters, action.payload];
        },
        updateFilters: (state, action) => {
            const newFilters = [...state.filters];
            newFilters[action.payload.key] = action.payload.data;
            state.filters = newFilters;
        },
        removeFilters: (state, action) => {
            state.filters.splice(action.payload, 1)
        },
        setSelectedFilter: (state, action) => {
            state.selectedFilter = action.payload;
        },
    },
});

export const {
    updateSelectedTool, setFilters,
    updateFilters,
    removeFilters,
    setSelectedFilter,
} = themeSlice.actions;

export default themeSlice.reducer;
