import AllRoutes from "./routes";

const App = () => {

  return (
    <AllRoutes />
  );
}

export default App;
