import { FormControl, Input, InputLabel } from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useState } from "react";
import { nodeServerPath } from "../../constant/api";

const ToolSubmitForm = ({ paymentData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  const [formData, setFormData] = useState({
    email: "",
    toolsName: "",
    useCase: "",
    description: "",
    link: "",
    isPaymentDone: true,
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleCheckout = async (e) => {
    e.preventDefault();

    setActiveButton(paymentData?.buttonId);
    setIsLoading(true);

    const stripePromise = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
    const body = {
      product: {
        name: paymentData?.name,
        price: paymentData?.price,
        quantity: 1,
      },
    };

    try {
      const response = await axios.post(
        `${nodeServerPath}/api/create-checkout-session`,
        body
      );
      const sessionId = response.data.id;
      localStorage.setItem("formData", JSON.stringify(formData));
      await stripePromise.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error("error from payment", error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
        setActiveButton(null);
      }, 3000);
    }
  };

  return (
    <div className="min-w-[320px]">
      <div>
        <FormControl fullWidth className="!mb-5">
          <InputLabel htmlFor="email">Email address</InputLabel>
          <Input type="email" id="email" required onChange={handleChange} />
        </FormControl>

        <FormControl fullWidth className="!mb-5">
          <InputLabel htmlFor="toolsName">Tools Name</InputLabel>
          <Input id="toolsName" required onChange={handleChange} />
        </FormControl>

        <FormControl fullWidth className="!mb-5">
          <InputLabel htmlFor="useCase">Use Case</InputLabel>
          <Input id="useCase" required onChange={handleChange} />
        </FormControl>

        <FormControl fullWidth className="!mb-5">
          <InputLabel htmlFor="description">Description</InputLabel>
          <Input multiline id="description" required onChange={handleChange} />
        </FormControl>

        <FormControl fullWidth className="!mb-5">
          <InputLabel htmlFor="link">Link</InputLabel>
          <Input type="url" id="link" required onChange={handleChange} />
        </FormControl>

        <div className="flex justify-end mt-5">
          <button
            onClick={handleCheckout}
            className="bg-[#3E44DE] text-white px-10 py-[10px] font-bold rounded-3xl hover:opacity-90 transition-all flex justify-center items-center disabled:opacity-50"
            disabled={isLoading && activeButton === paymentData?.buttonId}
            type="button"
          >
            {isLoading && activeButton === paymentData?.buttonId ? (
              <div className="flex items-center">
                <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-r-2 border-white mr-2"></div>
                Processing...
              </div>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ToolSubmitForm;
