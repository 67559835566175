import { useState, useEffect, Fragment } from "react";
import CategoryBubble from "../../components/bubbles/category-bubble";
import Pagination from "../../components/snippets/pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentCategory,
  setShowOnlyTable,
} from "../../reducers/globalReducer";
import { fetchCategories } from "../../utils/api";

function Categories() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const { collection } = useSelector((state) => state.categories);
  const BUBBLES_PER_PAGE = user?.bubblesPerPage || 50;
  const [categoryCollection, setCategoryCollection] = useState(collection);
  const [loading, setLoading] = useState(false);
  const [dataSets, setDataSets] = useState({});
  const [categories, setCategories] = useState([]);
  const [blueprints, setBlueprints] = useState({
    page: 0,
    perPage: 0,
    totalCategories: 0,
    totalPages: 0,
  });
  const [params, setParams] = useState({
    page: 1,
    perPage: BUBBLES_PER_PAGE,
  });

  useEffect(() => {
    dispatch(setShowOnlyTable(false));
  }, [dispatch]);

  useEffect(() => {
    setParams({ ...params, page: 1 });
    setDataSets({});
    setCategoryCollection(collection);
  }, [collection]);

  const handelFetchCategories = () => {
    setLoading(true);
    fetchCategories(setCategories, setBlueprints, params, user?.accessToken)
      .then((res) => {
        setDataSets({ ...dataSets, [params.page]: res });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (dataSets?.hasOwnProperty(params.page)) {
      setBlueprints(dataSets[params.page].blueprints);
      return setCategories(dataSets[params.page].categories);
    } else {
      handelFetchCategories();
    }
  }, [params, categoryCollection]);

  return (
    <div className={`relative w-full h-[calc(100vh-77px)]`}>
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <h4 className="text-base text-[var(--text-color-base)]">
            Loading...
          </h4>
        </div>
      ) : (
        <Fragment>
          {categories.length > 0 ? (
            <CategoryBubble
              categories={categories}
              loading={loading}
              setCurrentCategory={(data) => {
                dispatch(setCurrentCategory(data));
              }}
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center">
              <h4 className="text-base text-[var(--text-color-base)]">
                No category found!
              </h4>
            </div>
          )}
        </Fragment>
      )}

      {blueprints?.totalCategories > params.perPage && (
        <div className="fixed right-10 bottom-10">
          <Pagination
            currentPage={params.page}
            onClickLeftPage={() => {
              if (params.page <= 1) return;
              setParams({
                ...params,
                page: params.page - 1,
              });
            }}
            onClickRightPage={() => {
              setParams({
                ...params,
                page: params.page + 1,
              });
            }}
            itemsPerPage={params.perPage}
            totalValue={blueprints?.totalCategories}
          />
        </div>
      )}
    </div>
  );
}

export default Categories;
