import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { deleteMyPreferences, fetchMyPreferences } from "../../../utils/api";
import { Button } from "@headlessui/react";
import { Add, Close } from "@mui/icons-material";
import PreferenceModal from "../../../components/modals/preference-modal";
import ProfileLayout from "../../../layouts/profile";
import { toast } from "react-toastify";

function PreferencesPage() {
  const [loading, setLoading] = useState(false);
  const [preferences, setPreferences] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (!user?.accessToken) return;
    setLoading(true);
    fetchMyPreferences(user?.accessToken, setPreferences).finally(() => {
      setLoading(false);
    });
  }, [user?.accessToken]);

  const deletePreferences = async (id) => {
    await deleteMyPreferences(user.accessToken, id).then((res) => {
      toast.success(res?.message);
      fetchMyPreferences(user?.accessToken, setPreferences).finally(() => {
        setLoading(false);
      });
    });
  };

  return (
    <ProfileLayout>
      <div className="flex items-center justify-between">
        <h3 className="text-2xl font-bold text-[var(--text-color-base)]">
          Preferences
        </h3>

        <Button
          onClick={() => setIsOpen(true)}
          className="flex items-center gap-1 px-4 py-2 backdrop-blur-md shadow-filter rounded-md bg-[var(--primary-color)] hover:bg-[#9C3CD6]/30 active:bg-[var(--primary-color)] text-sm text-white transition-all"
        >
          Add <Add fontSize="small" />
        </Button>
      </div>

      <div className="w-full my-5 h-px bg-[#9C3CD6]/40" />

      {loading ? (
        <div className="p-5 flex flex-col items-center">
          <div className="loader border-t-4 border-blue-500 rounded-full w-12 h-12 animate-spin" />
        </div>
      ) : (
        <Fragment>
          {preferences?.length ? (
            <div className="w-full flex flex-wrap items-center gap-3">
              {preferences.map((preference, key) => (
                <div
                  key={preference.id}
                  className="flex items-center gap-[1px]"
                >
                  <div
                    className={`w-auto px-4 py-2 backdrop-blur-md shadow-filter rounded-l-md text-[var(--text-color-base)]`}
                    key={key}
                  >
                    {preference.name}
                  </div>
                  <Button
                    onClick={() => deletePreferences(preference.id)}
                    className="p-2 backdrop-blur-md shadow-filter rounded-r-md text-[var(--text-color-base)] cursor-pointer hover:bg-[var(--primary-color)] active:bg-[#9C3CD6]/30 transition-all"
                  >
                    <Close fontSize="small" />
                  </Button>
                </div>
              ))}
            </div>
          ) : (
            <h3 className="text-[var(--text-color-base)] text-center">
              No preferences found!
            </h3>
          )}
        </Fragment>
      )}

      {isOpen && (
        <PreferenceModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onSelect={() => {
            fetchMyPreferences(user?.accessToken, setPreferences).finally(
              () => {
                setLoading(false);
              }
            );
          }}
        />
      )}
    </ProfileLayout>
  );
}

export default PreferencesPage;
