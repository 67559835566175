import React, { Fragment, useState } from "react";
import axios from "axios";
import MemoryIcon from "@mui/icons-material/Memory";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import WorkspacesOutlinedIcon from "@mui/icons-material/WorkspacesOutlined";
import ExtensionOutlinedIcon from "@mui/icons-material/ExtensionOutlined";
import CurrencyBitcoinOutlinedIcon from "@mui/icons-material/CurrencyBitcoinOutlined";
import ConnectWithoutContactOutlinedIcon from "@mui/icons-material/ConnectWithoutContactOutlined";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { setCollection } from "../../reducers/categoriesReducer";
import { useLocation, useNavigate } from "react-router-dom";
import { CollectionMap } from "../../enums/collections";

const AiCategories = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const options = [
    {
      label: "AI Tools",
      value: "ToolTab",
      icon: MemoryIcon,
    },
    {
      label: "Tasks",
      value: "TaskTab",
      icon: TaskOutlinedIcon,
    },
    {
      label: "GPTs",
      value: "GPTTab",
      icon: WorkspacesOutlinedIcon,
    },
    {
      label: "Plugins",
      value: "PluginTab",
      icon: ExtensionOutlinedIcon,
    },
    {
      label: "Product hunt",
      value: "ProductHuntTab",
      icon: WysiwygIcon,
    },
    {
      label: "Crypto",
      value: "CryptoTab",
      icon: CurrencyBitcoinOutlinedIcon,
    },
    {
      label: "Social",
      value: "SocialTab",
      icon: ConnectWithoutContactOutlinedIcon,
    },
  ];

  const { currentTheme } = useSelector((state) => state.theme);

  const handleTabChange = (option, index) => {
    navigate("/");
    setActiveIndex(index);

    const collectionName = CollectionMap[option.value];

    // Send the collection name to the server
    const nodeServerPath = process.env.REACT_APP_HOST_URL || "localhost:3031";
    axios
      .post(`${nodeServerPath}/api/setCollection`, { collectionName })
      .then(() => {
        // Handle successful collection change
        dispatch(setCollection(option?.value));
      })
      .catch((error) => {
        // Handle error
        console.error("Error setting collection:", error);
      });
  };

  return (
    <Menu as="div" className="relative">
      {({ open }) => {
        setIsMenuOpen(open);

        return (
          <Fragment>
            <MenuButton
              className={`w-max h-max ml-2 ${
                location.pathname === "/" || isMenuOpen
                  ? "text-[var(--primary-color)] font-bold"
                  : "text-[var(--text-color-base)]"
              }`}
            >
              AI Categories
            </MenuButton>

            <MenuItems
              transition
              anchor="bottom end"
              className={`${currentTheme} z-[1000] mt-6 p-2 w-48 rounded-lg shadow-filter backdrop-blur origin-top-right transition duration-100 ease-out focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0`}
            >
              {options.map((option, index) => (
                <MenuItem key={index}>
                  <button
                    onClick={() => handleTabChange(option, index)}
                    className={`group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-[#9C3CD6]/20 text-white
                        ${
                          activeIndex === index
                            ? "bg-[var(--primary-color)] font-bold"
                            : "bg-transparent"
                        }
                  `}
                  >
                    {/* <AccountCircle fontSize="small" /> */}
                    <option.icon />
                    {option.label}
                  </button>
                </MenuItem>
              ))}
            </MenuItems>
          </Fragment>
        );
      }}
    </Menu>
  );
};

export default AiCategories;
