import React, { Fragment, useState } from 'react';
import useCanvas from '../../hooks/useCanvasBubbles';
import Loader from '../snippets/loader';
import ToolDetailsModal from '../modals/tool-details-modal';
import { useSelector } from 'react-redux';

const ToolBubble = ({ tools, loading }) => {
    const [singleTool, setSingleTool] = useState({});
    const [toolModalOpen, setToolModalOpen] = useState(false);
    const canvasRef = useCanvas(null, tools, null, setSingleTool, toolModalOpen, setToolModalOpen);
    const { currentTheme } = useSelector((state) => state.theme);

    return (
        <Fragment>
            {loading ? (
                <Loader />
            ) : (
                <canvas
                    key={currentTheme}
                    className={`${currentTheme === 'light-theme'
                        ? 'bg-[var(--bg-color)] bg-cover bg-no-repeat'
                        : 'bg-black'
                        } bg-contain h-full object-contain`}
                    ref={canvasRef}
                    style={{
                        backgroundImage: currentTheme === 'light-theme'
                            ? "url('/assets/banner-light-bg.png')"
                            : '',
                        backgroundColor: currentTheme !== 'light-theme' ? 'black' : '',
                        width: '100%',
                    }}
                />
            )}

            <ToolDetailsModal
                singleTool={singleTool}
                toolModalOpen={toolModalOpen}
                setToolModalOpen={setToolModalOpen}
            />
        </Fragment>
    );
};

export default ToolBubble;
