import React from 'react';

const Loader = () => {

    return (
        <div className="absolute inset-0 flex flex-col items-center top-[20%]">
            <div className="text-[var(--text-color-base)] mb-5">Loading bubbles...</div>
            <div className="loader border-t-4 border-blue-500 rounded-full w-12 h-12 animate-spin" />
        </div>
    );
};

export default Loader;