import {useState, useEffect, useMemo, useRef} from 'react';
import {useTable, useGlobalFilter, usePagination, useSortBy} from 'react-table';
import {useDispatch, useSelector} from 'react-redux';
import {ThreeDots} from 'react-loader-spinner';
import SearchIcon from '@mui/icons-material/Search';
import {twMerge} from 'tailwind-merge';
import ToolDetailsModal from '../modals/tool-details-modal';
import {updateSelectedTool} from '../../reducers/toolsReducer';

const AITable = ({tools, loading, showOnlyTable}) => {
    const dispatch = useDispatch();
    const tableRef = useRef(null);
    const [isMobile, setIsMobile] = useState(false);
    const [toolModalOpen, setToolModalOpen] = useState(false);
    const [singleTool, setSingleTool] = useState({});
    const {selectedTool} = useSelector((state) => state.tools);


    useEffect(() => {
        const checkIfMobile = () => setIsMobile(window.innerWidth <= 767);
        checkIfMobile(); // Initial check
        window.addEventListener('resize', checkIfMobile); // Event listener for window resize

        return () => window.removeEventListener('resize', checkIfMobile); // Cleanup
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tableRef.current && !tableRef.current.contains(event.target)) {
                dispatch(updateSelectedTool(null));
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dispatch]);

    const columns = useMemo(() => [
        {Header: '#', accessor: 'index', className: 'w-1/12'},
        {Header: 'Name', accessor: 'name', className: 'w-1/5'},
        {
            Header: 'Url', accessor: 'url', className: 'w-1/5',
            Cell: ({value}) => (
                <a className="text-blue-600 underline break-all overflow-hidden truncate w-24 md:w-48 inline-block"
                   href={value} target="_blank" rel="noopener noreferrer">{value}</a>
            )
        },
        {Header: 'Category', accessor: 'category', className: 'w-1/5'},
        {
            Header: 'URL Global Visit',
            accessor: 'totalvisits',
            className: 'w-1/5',
            Cell: ({value}) => {
                if (value === null || value === undefined || isNaN(value)) {
                    return <span>Not Available</span>;
                }
                const formattedNumber = Number(value).toLocaleString();
                return <span>{formattedNumber}</span>;
            }
        },
    ], []);

    const data = useMemo(() => {
        // Sort tools by their original order
        const sortedTools = Array.isArray(tools) ? [...tools].sort((a, b) => (a?.index || 0) - (b?.index || 0)) : [];


        return sortedTools.map((row, index) => ({
            _id: row._id,
            index: index + 1,
            name: row.name,
            url: row.url,
            category: row.category,
            totalvisits: row.stats ? row.stats['total-visits'] : 'Not Available',
            paraphrased_description: row.paraphrased_description,
        }));
    }, [tools]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state: {globalFilter},
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: 100,
                sortBy: [{id: 'index', desc: false}], // Sort by index in ascending order
            }
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    return (
        <div className={`p-4 md:p-10 ${showOnlyTable ? '!pt-16' : ''}`}>
            <div className="md:flex mb-3 justify-between items-center">
                <div className='flex items-center bg-[#E6E4F0] px-5 py-[8px] rounded-3xl max-h-[48px]'>
                    <SearchIcon className='text-[#5F5F5F] text-xl mr-1'/>
                    <input
                        type="text"
                        value={globalFilter || ''}
                        onChange={(e) => setGlobalFilter(e.target.value)}
                        placeholder="Search AI tools"
                        className="text-[#989898] p-0 text-lg bg-transparent focus:outline-none focus:border-0"
                    />
                </div>
            </div>

            {loading ? (
                <div className="flex justify-center items-center">
                    <ThreeDots color="#00BFFF" height={80} width={80}/>
                </div>
            ) : (
                <table ref={tableRef} className="w-full border-b-2 sm:shadow-2xl border-collapse" {...getTableProps()}>
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    className={twMerge(
                                        isMobile && ['Category', 'URL Global Visit'].includes(column.Header) && 'hidden',
                                        'bg-[#201F28] text-white font-bold py-2 px-4'
                                    )}
                                >
                                    {column.render('Header')}
                                    <span>
                                            {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                        </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);

                        return (
                            <tr
                                {...row.getRowProps()}
                                className="text-center p-2 border-b border-[#E6E4F0] bg-white shadow-lg sm:shadow-none mb-6 sm:mb-0 cursor-pointer hover:bg-gray-100 border-l-2 border-r-2"
                            >
                                {row.cells.map(cell => (
                                    <td
                                        {...cell.getCellProps({className: cell.column.className})}
                                        className={twMerge(
                                            isMobile && ['Category', 'URL Global Visit'].includes(cell.column.Header) && 'hidden',
                                            'font-bold py-2'
                                        )}
                                    >
                                        {cell.column.Header === 'Name' ? (
                                            <button
                                                className={twMerge(
                                                    'truncate w-28 md:w-auto cursor-pointer py-1 px-3 rounded-md text-sm active:bg-emerald-800',
                                                    selectedTool?._id === row.original._id ? 'bg-yellow-200' : 'bg-green-300'
                                                )}
                                                onClick={() => {
                                                    setSingleTool(row.original); // Set selected tool for the modal
                                                    dispatch(updateSelectedTool(row.original)); // Update the selected tool in Redux
                                                    setToolModalOpen(true); // Open modal if needed
                                                }}
                                            >
                                                {cell.render('Cell')}
                                            </button>
                                        ) : (
                                            cell.render('Cell')
                                        )}
                                    </td>
                                ))}
                            </tr>

                        );
                    })}
                    </tbody>

                </table>
            )}

            <ToolDetailsModal
                singleTool={singleTool}
                toolModalOpen={toolModalOpen}
                setToolModalOpen={setToolModalOpen}
            />
        </div>
    );
};

export default AITable;
