export const periodData = [
    {
      name: 'Week',
      value: 'week'
    },
    {
      name: 'Month',
      value: 'month'
    },
    {
      name: 'Year',
      value: 'year'
    },
  ]

export const bubbleContentData = [
    {
      name: 'Country Rank',
      value: 'country_rank'
    },
    {
      name: 'Category Rank',
      value: 'category_rank'
    },
    {
      name: 'Total Visit',
      value: 'total_visit'
    },
    {
      name: 'Global Rank',
      value: 'global_rank'
    },
  ]

export const bubbleSizeData = [
    {
      name: 'Total Visit',
      value: 'total_visit'
    },
    {
      name: 'Global Rank',
      value: 'global_rank'
    },
  ]