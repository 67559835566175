import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    currentCategory: null,
    showOnlyTable: false,
};

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setCurrentCategory: (state, action) => {
            state.currentCategory = action.payload;
        },
        setShowOnlyTable: (state, action) => {
            state.showOnlyTable = action.payload;
        },
    },
});

export const {
    setCurrentCategory,
    setShowOnlyTable,
} = globalSlice.actions;

export default globalSlice.reducer;
