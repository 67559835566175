import { Button } from "@headlessui/react";
import {
  ContentCopy,
  FacebookRounded,
  Instagram,
  LinkedIn,
  Twitter,
} from "@mui/icons-material";
import clsx from "clsx";
import React from "react";
import {
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { toast } from "react-toastify";

const SocialShare = ({ shareUrl }) => {
  const handleCopy = async () => {
    await navigator.clipboard.writeText(shareUrl);
    toast.success("Link copied successfully!");
  };

  return (
    <div className="w-max mx-auto flex flex-col items-center justify-center gap-2 p-2">
      <div className="flex items-center justify-between mb-1">
        <h2 className="text-lg font-bold text-[var(--text-color-base)]">
          Share on:
        </h2>
        <div className="grid grid-flow-col gap-4 ml-5">
          <FacebookShareButton
            url={shareUrl}
            className={clsx(
              "!bg-white rounded-full !p-1 flex items-center justify-center text-[var(--text-color-base)] hover:scale-110 transition-all"
            )}
          >
            <FacebookRounded className="!w-6 !h-6" />
          </FacebookShareButton>
          <TwitterShareButton
            url={shareUrl}
            className={clsx(
              "!bg-white rounded-full !p-1 flex items-center justify-center text-[var(--text-color-base)] hover:scale-110 transition-all"
            )}
          >
            <Twitter className="!w-6 !h-6" />
          </TwitterShareButton>
          <LinkedinShareButton
            url={shareUrl}
            className={clsx(
              "!bg-white rounded-full !p-1 flex items-center justify-center text-[var(--text-color-base)] hover:scale-110 transition-all"
            )}
          >
            <LinkedIn className="!w-6 !h-6" />
          </LinkedinShareButton>
          <InstapaperShareButton
            url={shareUrl}
            className={clsx(
              "!bg-white rounded-full !p-1 flex items-center justify-center text-[var(--text-color-base)] hover:scale-110 transition-all"
            )}
          >
            <Instagram className="!w-6 !h-6" />
          </InstapaperShareButton>
        </div>
      </div>
      <Button
        onClick={handleCopy}
        className="text-sm font-medium text-[var(--text-color-base)] text-center"
      >
        Copy Link <ContentCopy className="ml-1 !h-4 !w-4" />
      </Button>
    </div>
  );
};

export default SocialShare;
