import { useSelector } from "react-redux";
import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Description,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { Close } from "@mui/icons-material";
import { fetchPreferences, postPreferences } from "../../utils/api";
import { toast } from "react-toastify";

const PreferenceModal = ({ isOpen, onClose, onSelect }) => {
  const { currentTheme } = useSelector((state) => state.theme);
  const [loading, setLoading] = useState(false);
  const [preferences, setPreferences] = useState([]);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (!user?.accessToken) return;
    setLoading(true);
    fetchPreferences(user?.accessToken, setPreferences).finally(() => {
      setLoading(false);
    });
  }, [user?.accessToken]);

  const handelSelect = (preference) => {
    postPreferences(user?.accessToken, preference).then(async (res) => {
      if (onSelect) {
        onSelect();
      }
      toast.success(res?.message);
      await fetchPreferences(user?.accessToken, setPreferences);
    });
  };

  return (
    <Fragment>
      <Dialog
        onClose={onClose}
        open={isOpen}
        as="div"
        className={`${currentTheme} relative z-[999] focus:outline-none`}
      >
        <DialogBackdrop className="fixed top-[77px] inset-0 bg-[#9C3CD6]/10 backdrop-blur" />
        <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <DialogPanel
              transition
              className="relative w-full max-w-5xl rounded-2xl p-10 backdrop-blur-xl shadow-filter transition duration-300 ease-out"
            >
              <Button
                onClick={onClose}
                className="absolute right-4 top-4 text-[var(--text-color-base)] hover:scale-110 transition-all"
              >
                <Close />
              </Button>

              <DialogTitle
                as="h3"
                className="text-2xl font-bold text-center text-[var(--text-color-base)]"
              >
                Select Preferences
              </DialogTitle>
              <Description className="opacity-80 text-center text-[var(--text-color-base)]">
                Manage and customize your preferences, including categories like
                AI, social media, productivity, and more.
              </Description>

              <div className="w-full my-5 h-px bg-[#9C3CD6]/40" />

              {loading ? (
                <div className="p-5 flex flex-col items-center">
                  <div className="loader border-t-4 border-blue-500 rounded-full w-12 h-12 animate-spin" />
                </div>
              ) : (
                <Fragment>
                  {preferences.length ? (
                    <div className="w-full flex flex-wrap items-center justify-center gap-3">
                      {preferences.map((preference, key) => (
                        <div
                          onClick={() => handelSelect(preference.id)}
                          className={`w-auto px-4 py-2 backdrop-blur-md shadow-filter rounded-md text-[var(--text-color-base)] cursor-pointer hover:bg-[var(--primary-color)] active:bg-[#9C3CD6]/30 transition-all`}
                          key={key}
                        >
                          {preference.name}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <h3 className="text-[var(--text-color-base)] text-center">
                      No preferences found!
                    </h3>
                  )}
                </Fragment>
              )}
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default PreferenceModal;
