import React, { Fragment, useState } from "react";
import { identity } from "deso-protocol";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../reducers/authReducer";
import {
  setCurrentCategory,
  setShowOnlyTable,
} from "../../reducers/globalReducer";
import { useNavigate } from "react-router-dom";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import { AccountCircle, Grade, Logout } from "@mui/icons-material";
import FavouriteModal from "../../components/modals/favourite-modal";

export default function AvatarMenus() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { currentTheme } = useSelector((state) => state.theme);
  const navigate = useNavigate();
  const [isOpenFavourite, setIsOpenFavourite] = useState(false);

  const clearIndex = () => {
    dispatch(setCurrentCategory(null));
    dispatch(setShowOnlyTable(false));
    navigate("/");
  };

  const signOut = async () => {
    if (user?.authMethod === "DESO") {
      await identity.logout();
    }
    dispatch(logout());
    clearIndex();
  };

  return (
    <Fragment>
      <Menu>
        <MenuButton className="w-max h-max ml-2">
          <img src="/assets/avatar.svg" alt="Logo" className="w-8 h-8" />
        </MenuButton>

        <MenuItems
          transition
          anchor="bottom end"
          className={`${currentTheme} z-[1000] mt-6 p-2 w-40 rounded-lg shadow-filter backdrop-blur origin-top-right transition duration-100 ease-out focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0`}
        >
          <MenuItem>
            <button
              onClick={() => navigate(`/${user._id}/settings`)}
              className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-[#9C3CD6]/20 text-[var(--text-color-base)]"
            >
              <AccountCircle fontSize="small" />
              Profile
            </button>
          </MenuItem>
          <MenuItem>
            <button
              onClick={() => setIsOpenFavourite(true)}
              className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-[#9C3CD6]/20 text-[var(--text-color-base)]"
            >
              <Grade fontSize="small" />
              Favourites
            </button>
          </MenuItem>
          <MenuItem>
            <button
              onClick={signOut}
              className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-[#9C3CD6]/20 text-[var(--text-color-base)]"
            >
              <Logout fontSize="small" />
              Sign out
            </button>
          </MenuItem>
        </MenuItems>
      </Menu>

      {/* Favorite modal */}
      {isOpenFavourite && (
        <FavouriteModal open={isOpenFavourite} setOpen={setIsOpenFavourite} />
      )}
    </Fragment>
  );
}
