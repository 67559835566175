import {Outlet} from "react-router-dom"
import Header from "./header"
import {useDispatch, useSelector} from "react-redux";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PreferenceModal from "../components/modals/preference-modal";
import {useEffect, useState} from "react";
import {setPreferenceDate} from "../reducers/preferenceReducer";

export default function Layout() {
    const {currentTheme} = useSelector((state) => state.theme);
    const [isOpen, setIsOpen] = useState(false);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const {preferenceDate} = useSelector(state => state.preference);
    const dispatch = useDispatch();

    const handelClose = () => {
        const date = new Date();
        dispatch(setPreferenceDate(date));
        setIsOpen(false);
    }

    const compareDates = (date1, date2, dayCount = 2) => {
        const d1 = new Date(date1);
        const d2 = new Date(date2);

        if (isNaN(d1.getTime()) || isNaN(d2.getTime())) {
            throw new Error('Invalid date format');
        }

        const timeDifference = Math.abs(d1.getTime() - d2.getTime());
        const twoDaysInMilliseconds = dayCount * 24 * 60 * 60 * 1000;
        return timeDifference > twoDaysInMilliseconds;
    }

    useEffect(() => {
        if (!isAuthenticated) return;
        if (!preferenceDate) {
            setIsOpen(true)
        } else {
            setIsOpen(compareDates(preferenceDate, new Date()));
        }
    }, [preferenceDate, isAuthenticated])

    return (
        <div className={`${currentTheme}`}>
            <ToastContainer position="bottom-right" autoClose={3000} className="z-[1000]"
                            theme={currentTheme === 'light-theme' ? 'light' : 'dark'}/>
            <Header/>
            <main className="bg-[var(--bg-color)] min-h-[calc(100vh-77px)] overflow-y-auto">
                <Outlet/>

                {(isAuthenticated && isOpen) &&
                    <PreferenceModal
                        isOpen={isOpen}
                        onClose={handelClose}
                    />
                }
            </main>
        </div>
    )
}