import React, { Fragment } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import {
  removeFilters,
  setFilters,
  setSelectedFilter,
  updateFilters,
} from "../../reducers/toolsReducer";
import {
  Button,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import FilterDropdown from "../forms/filter-dropdown";
import {
  setCurrentCategory,
  setShowOnlyTable,
} from "../../reducers/globalReducer";
import { Check, ChevronRight } from "@mui/icons-material";
import clsx from "clsx";

const TopBar = ({ onBack }) => {
  const dispatch = useDispatch();
  const { filters, selectedFilter } = useSelector((state) => state.tools);
  const { currentCategory } = useSelector((state) => state.global);
  const filterConfig = {
    label: "Day",
    period: "week",
    bubbleContent: "total_visit",
    bubbleSize: "total_visit",
  };
  const { currentTheme } = useSelector((state) => state.theme);

  const deleteFilterItem = (index) => {
    dispatch(removeFilters(index));
  };

  const handelFilterData = (data) => {
    const newLabel = data.label.toLowerCase().replace(" ", "_");
    return {
      label: data.label,
      index: filters.length,
      value: `${newLabel}_${new Date().valueOf()}`,
      filterData: {
        period: data.period,
        bubbleContent: data.bubbleContent,
        bubbleSize: data.bubbleSize,
      },
    };
  };

  const createNewFilter = () => {
    dispatch(setFilters(handelFilterData(filterConfig)));
    dispatch(setSelectedFilter(handelFilterData(filterConfig)));
  };

  const updateConfigState = (key, value) => {
    const newData = { ...selectedFilter };

    if (key === "label") {
      newData.label = value;
    } else {
      newData.filterData = { ...newData.filterData, [key]: value };
    }
    dispatch(setSelectedFilter(newData));
    dispatch(updateFilters({ key: newData.index, data: newData }));
  };

  const handelSelectFilter = (data) => {
    dispatch(setSelectedFilter(data));
  };

  return (
    <div
      className={`flex items-center justify-between bg-transparent absolute top-[0px] left-0 right-0 z-10 py-2 px-3 md:px-8 gap-5 min-h-[55px]`}
    >
      <div className="flex items-center space-x-2">
        <Fragment>
          {currentCategory && (
            <Button
              title="Back"
              className="flex items-center bg-transparent text-[var(--text-color-base)] text-center text-lg p-0 font-bold rounded-3xl hover:opacity-90 transition-all"
              onClick={() => {
                dispatch(setCurrentCategory(null));
                dispatch(setShowOnlyTable(false));
                if (onBack) {
                  onBack();
                }
              }}
            >
              <ArrowBackIosIcon fontSize="small" />
            </Button>
          )}
        </Fragment>
      </div>

      {currentCategory && (
        <div className="flex justify-end items-center gap-2 z-[100002]">
          <div className="mx-auto w-52">
            <Listbox
              value={selectedFilter}
              onChange={(item) => {
                handelSelectFilter(item);
              }}
            >
              <ListboxButton
                className={clsx(
                  "shadow backdrop-blur relative block w-full rounded-lg py-1.5 px-3 text-left text-sm/6 text-[var(--text-color-base)]",
                  "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25",
                  "flex items-center justify-between gap-2"
                )}
              >
                {selectedFilter?.label || "Select Filter"}
                <ChevronRight
                  className="rotate-90 group pointer-events-none size-4 fill-white/60"
                  aria-hidden="true"
                />
              </ListboxButton>
              <ListboxOptions
                anchor="bottom"
                transition
                className={clsx(
                  `${currentTheme}`,
                  "mt-1 shadow backdrop-blur w-[var(--button-width)] rounded-lg p-2 [--anchor-gap:var(--spacing-1)] focus:outline-none",
                  "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0",
                  "h-auto max-h-[200px] overflow-y-auto"
                )}
              >
                {filters.map((data, key) => (
                  <ListboxOption
                    key={key}
                    value={data}
                    className="cursor-pointer group flex items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white/10"
                  >
                    <div
                      className={clsx(
                        "flex-1 text-sm/6 text-[var(--text-color-base)] flex items-center justify-between gap-2"
                      )}
                    >
                      {data.label}

                      {data !== selectedFilter ? (
                        <ClearIcon
                          fontSize="small"
                          className="text-red-400"
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteFilterItem(key);
                          }}
                        />
                      ) : (
                        <Check
                          fontSize="small"
                          className="text-[var(--text-color-base)]"
                        />
                      )}
                    </div>
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Listbox>
          </div>

          <FilterDropdown
            onOpen={createNewFilter}
            currentConfig={selectedFilter}
            updateConfigState={updateConfigState}
          />
        </div>
      )}
    </div>
  );
};

export default TopBar;
