import {NavLink} from "react-router-dom";
import Header from "../../layouts/header";
import {useSelector} from "react-redux";
import {ArrowBack} from "@mui/icons-material";

function ErrorPage() {
    const {currentTheme} = useSelector((state) => state.theme);
    return (
        <div className={`${currentTheme}`}>
            <Header/>
            <main className="bg-[var(--bg-color)]">
                <div
                    className={`w-full h-[calc(100vh-77px)] bg-repeat ${currentTheme === 'light-theme' ? 'bg-cover bg-no-repeat bg-center' : ''}`}
                    style={{
                        backgroundImage: `${currentTheme === 'light-theme' ? "url('/assets/banner-light-bg.png')" : "url('/assets/banner-dark-bg.png'), linear-gradient(90deg, rgba(2, 93, 44, 0.08) 0%, rgba(238, 189, 62, 0.08) 25%, rgba(79, 194, 187, 0.08) 50%, rgba(135, 21, 103, 0.08) 75%, rgba(90, 0, 161, 0.08) 100%)"}`,
                        width: '100%',
                    }}>
                    <div className="w-full h-full backdrop-blur-2xl p-8 flex items-center justify-center">
                        <div className="mx-auto max-w-screen-sm text-center">
                            <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-[var(--primary-color)]">404</h1>
                            <p className="mb-4 text-3xl tracking-tight font-bold md:text-4xl text-[var(--text-color-base)]">
                                Something's missing.
                            </p>
                            <p className="mb-4 text-lg font-light text-[var(--text-color-base)] opacity-70">
                                Sorry, we can't find that page. You'll find lots to explore on the home page.
                            </p>
                            <NavLink to="/"
                                     className="w-max mx-auto px-5 py-2.5 text-[var(--text-color-base)] hover:text-[var(--primary-color)] transition-all flex items-center justify-center gap-1">
                                <ArrowBack/>
                                Back to Homepage
                            </NavLink>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default ErrorPage;

