import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentCategory,
  setShowOnlyTable,
} from "../../reducers/globalReducer";
import { Button } from "@headlessui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthModal from "../../components/modals/auth-modal";
import AiCategories from "./ai-categories";

export default function NavbarItems() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const { currentCategory } = useSelector((state) => state.global);
  const navigate = useNavigate();
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);

  const onClickPopular = () => {
    dispatch(setCurrentCategory(null));
    dispatch(setShowOnlyTable(false));
    navigate("/popular");
  };

  const onClickRecent = () => {
    dispatch(setCurrentCategory(null));
    dispatch(setShowOnlyTable(false))
    navigate("/recent");
  };

  const onClickForYou = () => {
    if (isAuthenticated) {
      dispatch(setCurrentCategory(null));
      dispatch(setShowOnlyTable(false));
      navigate("/for-you");
    } else {
      setOpenModal(true);
    }
  };

  return (
    <Fragment>
      <Button
        className={`hover:text-[var(--primary-color)] whitespace-nowrap transition-all ${
          location.pathname === "/popular"
            ? "text-[var(--primary-color)] font-bold"
            : "text-[var(--text-color-base)]"
        }`}
        onClick={onClickPopular}
      >
        Popular
      </Button>

      <Button
        className={`hover:text-[var(--primary-color)] whitespace-nowrap transition-all ${
          location.pathname === "/recent" ? "text-[var(--primary-color)] font-bold" : "text-[var(--text-color-base)]"
        }`}
        onClick={onClickRecent}
      >
        Recent
      </Button>

      <Button
        className={`hover:text-[var(--primary-color)] whitespace-nowrap transition-all ${
          location.pathname === "/for-you"
            ? "text-[var(--primary-color)] font-bold"
            : "text-[var(--text-color-base)]"
        }`}
        onClick={onClickForYou}
      >
        For You
      </Button>

      {!currentCategory && <AiCategories />}

      <Link
        to="http://ai-bubbles.com:8080"
        target="_blank"
        className="text-[var(--text-color-base)] hover:text-[var(--primary-color)] whitespace-nowrap transition-all"
      >
        News
      </Link>

      {/* Auth Modal */}
      <AuthModal open={openModal} setOpen={setOpenModal} />
    </Fragment>
  );
}
