import React, { Fragment, useEffect, useState } from "react";
import { Button, Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Favorite, Close, Public } from "@mui/icons-material";
import { getFavourites, removeFavourite } from "../../utils/api";
import Loader from "../snippets/loader";
import Pagination from "../snippets/pagination";
import SocialShare from "../snippets/social-share";

const FavouriteModal = ({ open, setOpen }) => {
  const { user } = useSelector((state) => state.auth);
  const [favoriteData, setFavoriteData] = useState(user?.favorites || []);
  const { currentTheme } = useSelector((state) => state.theme);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [blueprints, setBlueprints] = useState({
    page: 0,
    perPage: 0,
    totalPages: 0,
  });
  const [params, setParams] = useState({
    page: 1,
    perPage: user?.bubblesPerPage || 50,
  });

  // Function to remove an item from the data state
  const removeItem = async (id) => {
    await removeFavourite(user, id, setFavoriteData, setBlueprints, params);

    if (favoriteData.length <= 1 && params.page > 1) {
      return setParams({
        ...params,
        page: params.page - 1,
      });
    }
    setLoading(true);
    getFavourites(
      user?._id,
      setFavoriteData,
      setBlueprints,
      user?.accessToken,
      params
    ).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (user?._id) {
      setLoading(true);
      getFavourites(
        user?._id,
        setFavoriteData,
        setBlueprints,
        user?.accessToken,
        params
      ).finally(() => {
        setLoading(false);
      });
    } else {
      setFavoriteData([]);
    }
  }, [user?._id, params]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        document.documentElement.style.overflow = "auto";
      }, 500);
    } else {
      document.documentElement.style.removeProperty("overflow");
    }
  }, [open]);

  const shareUrl = `${process.env.REACT_APP_BASE_URL}/${user._id}/favorites`;

  return (
    <Dialog
      open={open}
      as="div"
      className={`${currentTheme} relative z-50 focus:outline-none`}
      onClose={() => setOpen(false)}
    >
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <DialogPanel
            transition
            className="relative w-full max-w-3xl rounded-2xl p-10 backdrop-blur-xl shadow-filter transition duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
          >
            <Button
              onClick={() => setOpen(false)}
              className="absolute right-4 top-4 text-[var(--text-color-base)] hover:scale-110 transition-all"
            >
              <Close />
            </Button>
            <DialogTitle
              as="h1"
              className="text-3xl font-bold text-[var(--text-color-base)] text-center border-none mb-10"
            >
              Favourite
            </DialogTitle>

            <div className="w-full">
              {loading ? (
                <Loader />
              ) : (
                <Fragment>
                  {favoriteData && favoriteData.length ? (
                    <Fragment>
                      <ul className="grid grid-flow-row gap-5">
                        {favoriteData.map((item, key) => (
                          <li
                            key={key}
                            className="flex items-center justify-between gap-2 py-2 px-3 rounded-lg border border-[var(--border-color)] text-lg text-[var(--text-color-base)]"
                          >
                            <Link to={item?.toolLink}>{item?.toolName}</Link>
                            <div className="flex items-center gap-2">
                              <Favorite />
                              <Close
                                className="cursor-pointer hover:text-red-500 transition-all"
                                onClick={() => removeItem(item._id)}
                              />
                            </div>
                          </li>
                        ))}
                      </ul>
                    </Fragment>
                  ) : (
                    <p className="my-5 text-center text-[var(--text-color-base)]">
                      No favourite item found!
                    </p>
                  )}
                </Fragment>
              )}
              {blueprints?.totalPages >= blueprints.page && (
                <div className="w-full flex items-center justify-between gap-5 mt-5">
                  <Button
                    onClick={() => {
                      navigate(`/${user._id}/favorites`);
                      setOpen(false);
                    }}
                    className="w-max text-center flex items-center justify-center gap-2 text-[var(--text-color-base)] hover:text-[var(--primary-color)] transition-all"
                  >
                    <Public />
                    View Details
                  </Button>

                  <div className="w-auto">
                    <Pagination
                      currentPage={params.page}
                      onClickLeftPage={() => {
                        if (params.page <= 1) return;
                        setParams({
                          ...params,
                          page: params.page - 1,
                        });
                      }}
                      onClickRightPage={() => {
                        setParams({
                          ...params,
                          page: params.page + 1,
                        });
                      }}
                      itemsPerPage={params.perPage}
                      totalPages={blueprints?.totalPages}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="mt-5 w-max mx-auto">
              <SocialShare shareUrl={shareUrl} />
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
export default FavouriteModal;
